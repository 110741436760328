import React, { Component } from "react";
import { connect } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import { Card, Form } from "react-bootstrap";
import BestSellingProductsTable from "./BestSellingProductsTable";
import SecondaryBannersTable from "./SecondaryBannersTable";
import { uploadFile } from "../../services/fileUploadService";
import API from "../../utils/API";
import KuwaUSPTable from "./KuwaUSPTable";
import BrandsTable from "./BrandsTable";
import SocialMediaLinksTable from "./SocialMediaLinksTable";
import HealthCategoriesTable from "./HealthCategoriesTable";
import MenuTableHealthGoals from "./MenuTableHealthGoals";
import MenuTableBrands from "./MenuTableBrands";
import MenuTableCategories from "./MenuTableCategories";
import PrimaryBannersTable from "./PrimaryBannersTable";
import BestSellingCollectionTable from './BestSellingCollectionTable'
import Toggle from 'react-toggle';
import Select from "react-select";

/*
https://api.kuwa.bevaleo.dev/cms/brand
https://api.kuwa.bevaleo.dev/cms/health-goal
https://api.kuwa.bevaleo.dev/cms/menu-items/brand
https://api.kuwa.bevaleo.dev/cms/menu-items/health  "for Explore Category"
https://api.kuwa.bevaleo.dev/cms/hero-banner
https://api.kuwa.bevaleo.dev/cms/secondary-banner
https://api.kuwa.bevaleo.dev/cms/health-category
https://api.kuwa.bevaleo.dev/cms/contact-detail
https://api.kuwa.bevaleo.dev/cms/social-media
https://api.kuwa.bevaleo.dev/cms/kuwa-usp
https://api.kuwa.bevaleo.dev/cms/best-selling
*/
// const BASE_URL_PRODUCTS = "/api/v1/products";
// https:api.kuwa.bevaleo.dev/cms/collectionBestSeller "for bestSeller Collection"
const BASE_URL_PRODUCTS = "module/search/products/frequently-bought-products?key="
// const BASE_URL_PRODUCT_TYPES = "/api/v1/product/types/all";
const BASE_URL_PRODUCT_TYPES = "/api/v1/new/product/types/all";
const BASE_URL_COUNTRIES = "/api/v1/active/countries";

// const BASE_URL_PRODUCTS_SELECTED_COLLECTION = "api/v1/private/product/type"

class WebsiteCMS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      productTypes: [],
      productTypesIncludeInActive:[],
      productTypeBrands: [],
      productTypeHealthGoals: [],
      productTypeHealthGoalsIncludeInActive:[],
      productTypeBrandsIncludeInActive:[],
      // bestSelling: {},
      bestSelling:[],
      kuwaUSPs: [],
      socialMediaLinks: [],
      contactDetail: {},
      couponBanner: {},
      heroBanner: {},
      primaryBanners: [],
      secondaryBanners: [],
      brand: [],
      healthCategories: [],
      menuBanner: {},
      menuItemBrands: [],
      menuItemCategories: [],
      menuItemHealthGoals: [],
      collections:[],
      // selectCollection
      selectedBestSellingCollectionOption:{},

      cmsPageData: [],
      countries:[],
      selectedCountry:{id:6,value:6,label:"Qatar"},

    };
    
  }
 


  async componentDidMount() {
    await Promise.all([
     
      this.fetchProductTypes(),
      this.fetchProductTypeBrands(),
      this.fetchProductTypeHealthGoals(),
      this.fetchCountries()
    ]);
    // await this.fetchProductTypes();
    this.fetchDependencies();
  }

  fetchProductsCollectionWise = (option)=>{
    this.setState({selectedBestSellingCollectionOption:option},()=>
    { this.fetchProducts(option);
      this.fetchBestSelling(option);})
     
  }

  fetchProducts = async (option) => {
    console.log("handleProductOption",option)
   const {selectedCountry} = this.state;
    const collectionId = option.id || ""
    // try{
    //   this.showLoading();
    //   const {data={}} = await API.get(BASE_URL_PRODUCTS)
    //   console.log("aaaaaa",data)
    //   this.setState({
    //     products:data.map((product)=>({
    //       id:product.id,
    //       name:product.name ?? "",
    //     }))
    //   })
    // }

    try{
      this.showLoading();
      const {data={}} = await API.get(`api/v1/new/product/type/${collectionId}?country=${selectedCountry.id}`);
      const productsData = data?.products?.[0].product;
      console.log("productData",data)
         this.setState({
        products:productsData.map((product)=>({
          id:product.id,
          productName:product.name ?? "",
        }))
      })
    }

     catch (error) {
      console.log("fetchProducts:: Error on fetching products!", error);
    } finally {
      this.hideLoading();
    }
  };

  fetchProductTypes = async () => {
    const {selectedCountry} = this.state
    try {
      this.showLoading();
      const { data = {} } = await API.get(BASE_URL_PRODUCT_TYPES,{
        params: { country: selectedCountry.id },
      });
      const { list: productTypes = [] } = data;
      const {list :productTypesIncludeInActive = []} = data
      const visibleProductTypes =  productTypes.filter(data=>data.visible === true )
      this.setState({ productTypes: visibleProductTypes });
      this.setState({productTypesIncludeInActive:productTypesIncludeInActive})
      console.log("zcsshb",visibleProductTypes)
    } catch (error) {
      console.log(
        "fetchProductTypes:: Error on fetching product types!",
        error
      );
    } finally {
      this.hideLoading();
    }
  };

  // https://api.kuwa.bevaleo.dev/api/v1/private/product/types?super=BRAND
  fetchProductTypeBrands = async () => {
    const {selectedCountry} = this.state
    try {
      this.showLoading();
      const { data = {} } = await API.get(BASE_URL_PRODUCT_TYPES, {
        params: { super: "BRAND",country: selectedCountry.id},
      });
      const { list: productTypeBrands = [] } = data;
      const {list: productTypeBrandsIncludeInActive = []} =data
      const visibleProductTypesBrands = productTypeBrands.filter(data=>data.visible === true)
      this.setState({ productTypeBrands:visibleProductTypesBrands });
      this.setState({productTypeBrandsIncludeInActive:productTypeBrandsIncludeInActive})
    } catch (error) {
      console.log(
        "fetchProductTypes:: Error on fetching product type brands!",
        error
      );
    } finally {
      this.hideLoading();
    }
  };

  // https://api.kuwa.bevaleo.dev/api/v1/private/product/types?super=HEALTH
  fetchProductTypeHealthGoals = async () => {
    const {selectedCountry} = this.state
    try {
      this.showLoading();
      const { data = {} } = await API.get(BASE_URL_PRODUCT_TYPES, {
        params: { super: "HEALTH",country: selectedCountry.id},
      });
      const { list: productTypeHealthGoals = [] } = data;
      const { list: productTypeHealthGoalsIncludeInActive= []} = data;
      const visibleProductTypeHealthGoals = productTypeHealthGoals.filter(data=>data.visible=== true)
      this.setState({ productTypeHealthGoals :visibleProductTypeHealthGoals });
      this.setState({productTypeHealthGoalsIncludeInActive:productTypeHealthGoalsIncludeInActive})
    } catch (error) {
      console.log(
        "fetchProductTypes:: Error on fetching product type health goals!",
        error
      );
    } finally {
      this.hideLoading();
    }
  };

  fetchCountries = async() =>{
    try{
      this.showLoading();
      const {data=[]} = await API.get(BASE_URL_COUNTRIES);
      console.log("fetchCountries",data);
      const countries = data.map(country => {
        return {
          value: country.id,
          label: country.name,
          id: country.id,
        }
      })
      this.setState({countries:countries});
    }
    catch{

    }
  }

  fetchDependencies = () => {
    // this.fetchKuwaUSP();
    // this.fetchContactDetails();
    // this.fetchSocialMediaLinks();
    // this.fetchCouponBanner();
    // this.fetchHeroBanner();
    this.fetchBestSelling();
    // this.fetchPrimaryBanners();
    // this.fetchSecondaryBanners();
    // this.fetchBrand();
    // this.fetchHealthCategories();
    // this.fetchMenuBanner();
    // this.fetchMenuItemBrands();
    // this.fetchMenuListItems();
    // this.fetchMenuItemHealthGoals();
    // this.fetchBestSellingCollections();
    this.fetchCmsPage();
  };


  componentDidUpdate(prevProps, prevState) {
   const {selectedBestSellingCollectionOption} = this.state;
    console.log("selectedBestSellingCollectionOption", selectedBestSellingCollectionOption);
    if (prevState.selectedCountry.id !== this.state.selectedCountry.id) {
      this.fetchCmsPage();
      this.fetchProductTypes();
      this.fetchProductTypeBrands();  
      this.fetchProductTypeHealthGoals();
      // this.setState({selectedBestSellingCollectionOption:{}},()=>{
      //   this.fetchProductsCollectionWise()
      //  })
      this.fetchBestSelling();
      if(selectedBestSellingCollectionOption){

      
      this.setState({selectedBestSellingCollectionOption:selectedBestSellingCollectionOption.label=""})
      }
    }
  }

  fetchCmsPage = async()=>{
    const {selectedCountry,cmsPageData} = this.state;
   
    try{
      this.showLoading();
      const {data:cmsPageData} = await API.get(`cms/complete-cms/${selectedCountry.id}`);
      this.setState({cmsPageData:cmsPageData},()=>this.fetchCmsDataSectionWise())
     
    }
    
    catch{
      this.hideLoading()
    }
    finally{
      this.hideLoading()
    }
  }


  fetchCmsDataSectionWise = ()=>{
    const {cmsPageData} = this.state
   console.log("cmsPageData",cmsPageData)

   const couponBannerData= cmsPageData?.find((data)=>data.sectionHeading=="coupon_banner");
   const heroBannerData= cmsPageData?.find((data)=>data.sectionHeading=="hero_banner");
   const contactDetailsData= cmsPageData?.find((data)=>data.sectionHeading=="contact_details");
   const primaryBannerData= cmsPageData?.find((data)=>data.sectionHeading=="primary_banner");
   const socialMediaLinksData= cmsPageData?.find((data)=>data.sectionHeading=="social_media_links");
   const collectionBestSellerData= cmsPageData?.find((data)=>data.sectionHeading=="collection_best_seller");
   const secondaryBannerData= cmsPageData?.find((data)=>data.sectionHeading=="secondary_banners");
   const menuItemsBrandListData= cmsPageData?.find((data)=>data.sectionHeading=="menu_items_brand_list");
   const healthCategoriesData= cmsPageData?.find((data)=>data.sectionHeading=="health_categories_list");
   const menuBannerData= cmsPageData?.find((data)=>data.sectionHeading=="menu_banners");
   const menuListItemsData= cmsPageData?.find((data)=>data.sectionHeading=="menu_list_items");
   const menuItemsHealthListrData= cmsPageData?.find((data)=>data.sectionHeading=="menu_items_health_list");
   const kuwaUSPData = cmsPageData?.find((data)=>data.sectionHeading=="kuwa_usp") || [];

    const couponBanner =  couponBannerData.data[0] || {}
    const contactDetails =  contactDetailsData.data[0] || {}
    const heroBanner =  heroBannerData.data[0] || {}
    const menuBanner =  menuBannerData.data[0] || {}



    const kuwaUSPs =  kuwaUSPData.data || []
    const socialMediaLinks =  socialMediaLinksData.data || []
    const primaryBanners =  primaryBannerData.data || []
    const secondaryBanners =  secondaryBannerData.data || []
    const brand =  menuItemsBrandListData.data || []
    const healthCategories =  healthCategoriesData.data || []
    const collections =  collectionBestSellerData.data || []
    const menuItemHealthGoals =  menuItemsHealthListrData.data || []
    const menuItemCategories =  menuListItemsData.data || []

    this.setState({
       couponBanner: couponBanner,
        kuwaUSPs: kuwaUSPs,
        socialMediaLinks:socialMediaLinks, 
        contactDetails:contactDetails,
        heroBanner:heroBanner,
        primaryBanners:primaryBanners,
        secondaryBanners:secondaryBanners,
        brand:brand,
        healthCategories:healthCategories,
        collections:collections,
        menuItemHealthGoals:menuItemHealthGoals,
        menuBanner:menuBanner,
        menuItemCategories:menuItemCategories
      });


  }

  fetchBestSelling = async (option) => {
    const {selectedBestSellingCollectionOption,selectedCountry} = this.state;
    // const collectionId = option?.id || ""
    const collectionId = selectedBestSellingCollectionOption?.id || ""
    if(collectionId){
    try {
     
      this.showLoading();
      const data = await API.get(`cms/collectionBestSeller/${collectionId}?country=${selectedCountry.id}`);
      console.log("fetchingProducts",data);
      this.setState({ bestSelling:data.data });
      // console.log("bestSelling",bestSelling)
    } catch (error) {
      console.log("fetchBestSelling:: Error on fetching best selling!", error);
      this.showErrorNotification(
        "Error on fetching best selling. " + error.message ?? ""
      );
    } finally {
      this.hideLoading();
    }
  }
  else{
    console.log("")
  }
  };



  // fetchKuwaUSP = async () => {
  //   try {
  //     this.showLoading();
  //     const { data: kuwaUSPs } = await API.get("/cms/kuwa-usp");
  //     this.setState({ kuwaUSPs });
  //   } catch (error) {
  //     console.log("fetchKuwaUSP:: Error on fetching kuwa usps!", error);
  //     this.showErrorNotification(
  //       "Error on fetching kuwa usps. " + error.message ?? ""
  //     );
  //   } finally {
  //     this.hideLoading();
  //   }
  // };

  // fetchSocialMediaLinks = async () => {
  //   try {
  //     this.showLoading();
  //     const { data: socialMediaLinks } = await API.get("/cms/social-media");
  //     this.setState({ socialMediaLinks });
  //   } catch (error) {
  //     console.log(
  //       "fetchSocialMediaLinks:: Error on fetching social media links!",
  //       error
  //     );
  //     this.showErrorNotification(
  //       "Error on fetching social media links. " + error.message ?? ""
  //     );
  //   } finally {
  //     this.hideLoading();
  //   }
  // };

  // fetchContactDetails = async () => {
  //   try {
  //     this.showLoading();
  //     const { data: contactDetails } = await API.get("/cms/contact-detail");
  //     const contactDetail =
  //       contactDetails && contactDetails.length ? contactDetails[0] ?? {} : {};
  //     this.setState({ contactDetail });
  //   } catch (error) {
  //     console.log(
  //       "fetchContactDetails:: Error on fetching contact details!",
  //       error
  //     );
  //     this.showErrorNotification(
  //       "Error on fetching contact details. " + error.message ?? ""
  //     );
  //   } finally {
  //     this.hideLoading();
  //   }
  // };

  // fetchCouponBanner = async () => {
  //   try {
  //     this.showLoading();
  //     const { data: couponBanners } = await API.get("/cms/coupon-banner");
  //     const couponBanner =
  //       couponBanners && couponBanners.length ? couponBanners[0] : {};
  //     this.setState({ couponBanner });
  //   } catch (error) {
  //     console.log(
  //       "fetchCouponBanner:: Error on fetching coupon banner!",
  //       error
  //     );
  //     this.showErrorNotification(
  //       "Error on fetching coupon banner. " + error.message ?? ""
  //     );
  //   } finally {
  //     this.hideLoading();
  //   }
  // };

  // fetchHeroBanner = async () => {
  //   try {
  //     this.showLoading();
  //     const { data: heroBanners } = await API.get("/cms/hero-banner");
  //     const heroBanner =
  //       heroBanners && heroBanners.length ? heroBanners[0] : {};
  //     this.setState({ heroBanner });
  //   } catch (error) {
  //     console.log("fetchHeroBanner:: Error on fetching hero banner!", error);
  //     this.showErrorNotification(
  //       "Error on fetching hero banner. " + error.message ?? ""
  //     );
  //   } finally {
  //     this.hideLoading();
  //   }
  // };

 

  // fetchPrimaryBanners = async () => {
  //   try {
  //     this.showLoading();
  //     const { data: primaryBanners = {} } = await API.get(
  //       "/cms/primary-banner"
  //     );
  //     this.setState({ primaryBanners });
  //   } catch (error) {
  //     console.log(
  //       "fetchPrimaryBanners:: Error on fetching primary banners!",
  //       error
  //     );
  //     this.showErrorNotification(
  //       "Error on fetching primary banners. " + error.message ?? ""
  //     );
  //   } finally {
  //     this.hideLoading();
  //   }
  // };

  // fetchSecondaryBanners = async () => {
  //   try {
  //     this.showLoading();
  //     const { data: secondaryBanners = {} } = await API.get(
  //       "/cms/secondary-banner"
  //     );
  //     this.setState({ secondaryBanners });
  //   } catch (error) {
  //     console.log(
  //       "fetchSecondaryBanners:: Error on fetching secondary banners!",
  //       error
  //     );
  //     this.showErrorNotification(
  //       "Error on fetching secondary banners. " + error.message ?? ""
  //     );
  //   } finally {
  //     this.hideLoading();
  //   }
  // };

  // fetchBrand = async () => {
  //   try {
  //     this.showLoading();
  //     const { data: brand = [] } = await API.get("/cms/brand");
  //     this.setState({ brand });
  //   } catch (error) {
  //     console.log("fetchBrand:: Error on fetching brand!", error);
  //     this.showErrorNotification(
  //       "Error on fetching brand! " + error.message ?? ""
  //     );
  //   } finally {
  //     this.hideLoading();
  //   }
  // };

  // fetchHealthCategories = async () => {
  //   try {
  //     this.showLoading();
  //     const { data: healthCategories = [] } = await API.get(
  //       "/cms/health-category"
  //     );
  //     this.setState({ healthCategories });
  //   } catch (error) {
  //     console.log(
  //       "fetchHealthCategories:: Error on fetching health categories!",
  //       error
  //     );
  //     this.showErrorNotification(
  //       "Error on fetching health categories! " + error.message ?? ""
  //     );
  //   } finally {
  //     this.hideLoading();
  //   }
  // };
  // fetchBestSellingCollections = async () =>{
  //   try {
  //     this.showLoading();
  //     const {data:collections} = await API.get(
  //       "/cms/collectionBestSeller"
  //     );
  //     this.setState({ collections});
  //   } catch (error) {
  //     console.log(
  //       "fetchHealthBestSellingCollection:: Error on fetching bestSelling Collection!",
  //       error
  //     );
  //     this.showErrorNotification(
  //       "Error on fetching health categories! " + error.message ?? ""
  //     );
  //   } finally {
  //     this.hideLoading();
  //   }
  // }
  

  // fetchMenuItemHealthGoals = async () => {
  //   try {
  //     this.showLoading();
  //     const { data: menuItemHealthGoals } = await API.get(
  //       "/cms/menu-items/health"
  //     );
      
  //     this.setState({ menuItemHealthGoals });
  //   } catch (error) {
  //     console.log(
  //       "fetchMenuItemHealthGoals:: Error on fetching menu item health goals!",
  //       error
  //     );
  //     this.showErrorNotification(
  //       "Error on fetching menu item health goals! " + error.message ?? ""
  //     );
  //   } finally {
  //     this.hideLoading();
  //   }
  // };

  // fetchMenuBanner = async () => {
  //   const {menuBanner} = this.state;
  //   // if(menuBanner.image && menuBanner.redirectionLink){
  //   try {
  //     this.showLoading();
  //     const { data: menuBanners } = await API.get("/cms/menu-banner");
  //     const menuBanner =
  //       menuBanners && menuBanners.length ? menuBanners[0] : {};
  //     this.setState({ menuBanner });
  //   } catch (error) {
  //     console.log("fetchMenuBanner:: Error on fetching menu banner!", error);
  //     this.showErrorNotification(
  //       "Error on fetching menu banner! " + error.message ?? ""
  //     );
  //   } finally {
  //     this.hideLoading();
  //   }
  // // }
  // };

  // fetchMenuItemBrands = async () => {
  //   try {
  //     this.showLoading();
  //     const { data: menuItemBrands } = await API.get("/cms/menu-items/brand");
  //     this.setState({ menuItemBrands });
  //   } catch (error) {
  //     console.log(
  //       "fetchMenuItemBrands:: Error on fetching menu item brands!",
  //       error
  //     );
  //     this.showErrorNotification(
  //       "Error on fetching menu item brands! " + error.message ?? ""
  //     );
  //   } finally {
  //     this.hideLoading();
  //   }
  // };

  // fetchMenuListItems = async () => {
  //   try {
  //     this.showLoading();
  //     const { data: menuItemCategories } = await API.get(
  //       "/cms/menu-list-items"
  //     );
  //     this.setState({ menuItemCategories });
  //   } catch (error) {
  //     console.log(
  //       "fetchMenuItemCategories:: Error on fetching menu list items!",
  //       error
  //     );
  //     this.showErrorNotification(
  //       "Error on fetching menu list items! " + error.message ?? ""
  //     );
  //   } finally {
  //     this.hideLoading();
  //   }
  // };

 

  handleBrandsUpdated = (updatedBrands) => {
    const brand = {
      ...this.state.brand,
      brand: updatedBrands,
    };
    this.setState({ brand });
  };

  handlePrimaryBannersUpdated = (updatedPrimaryBanners) => {
    this.setState({ primaryBanners: updatedPrimaryBanners });
  };

  handleSecondaryBannersUpdated = (updatedSecondaryBanners) => {
    this.setState({ secondaryBanners: updatedSecondaryBanners });
  };

  handleHealthCategoriesUpdated = (updatedHealthCategories) => {
    this.setState({ healthCategories: updatedHealthCategories });
  };

  handleBestSellingCollectionUpdate = (updateBestSellingCollection) => {
    this.setState({collections: updateBestSellingCollection})
  }

  handleBestSellingProductsUpdated = (updatedProducts) => {
    // const bestSelling = {
    //   ...this.state.bestSelling,
    //   products: updatedProducts,
    // };
    console.log("updatedProducts",updatedProducts)
    this.setState({ bestSelling: updatedProducts  });
  };

  handleMenuItemHealthGoalsUpdated = (updatedMenuItemHealthGoals) => {
    this.setState({ menuItemHealthGoals: updatedMenuItemHealthGoals });
  };

  handleMenuItemBrandsUpdated = (updatedMenuItemBrands) => {
    this.setState({ menuItemBrands: updatedMenuItemBrands });
  };

  handleMenuItemCategoriesUpdated = (updatedMenuItemCategories) => {
    this.setState({ menuItemCategories: updatedMenuItemCategories });
  };

  handleKuwaUSPsUpdated = (updatedKuwaUSPs) => {
    this.setState({ kuwaUSPs: updatedKuwaUSPs });
  };

  handleSocialMediaLinksUpdated = (updatedSocialMediaLinks) => {
    this.setState({ socialMediaLinks: updatedSocialMediaLinks });
  };

  handleImageFileSelect = async (event) => {
    if (event.target.files && event.target.files.length) {
      const imageFile = event.target.files[0];

      try {
        this.showLoading();
        const { data: uploadDetails } = await uploadFile(imageFile);
        const heroBanner = {
          ...this.state.heroBanner,
          [event.target.name]: uploadDetails?.s3Url ?? "",
        };
        this.setState({ heroBanner });
      } catch (error) {
        console.log(
          "handleImageFileSelect:: Error on uploading image file!",
          error
        );
        this.showErrorNotification(
          "Error on uploading image file! " + error.message ?? ""
        );
      } finally {
        this.hideLoading();
      }
    }
  };

  handleMenuBannerImageFileSelect = async (event) => {
    if (event.target.files && event.target.files.length) {
      const imageFile = event.target.files[0];

      try {
        this.showLoading();
        const { data: uploadDetails } = await uploadFile(imageFile);
        const menuBanner = {
          ...this.state.menuBanner,
          image: uploadDetails?.s3Url ?? "",
        };
        this.setState({ menuBanner });
      } catch (error) {
        console.log(
          "handleMenuBannerImageFileSelect:: Error on uploading image file!",
          error
        );
        this.showErrorNotification(
          "Error on uploading image file! " + error.message ?? ""
        );
      } finally {
        this.hideLoading();
      }
    }
  };

  readImageFile = (imageFile) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const heroBanner = {
        ...this.state.heroBanner,
        imageSrc: [reader.result],
      };
      this.setState({ heroBanner });
    }.bind(this);
    reader.readAsDataURL(imageFile);
  };

   //   this.saveHealthCategories(),
    //   this.saveSocialMediaLinks(),
    //   this.saveKuwaUSP(),
    //   this.saveContactDetail(),
    //   this.saveCouponBanner(),
    //   this.saveHeroBanner(),
    
    //   this.savePrimaryBanners(),
    //   this.saveSecondaryBanners(),
    //   this.saveBrands(),
    //   this.saveMenuBanner(),
    //   this.saveMenuItemBrands(),
    //   this.saveMenuItemCategories(),
    //   this.saveMenuItemHealthGoals(),
    //   this.saveBestSellingCollections(),
    // ]);

  handleSaveCMS = async () => {
    try{
      await Promise.all([
        await this.handleSaveCmsPage(),
        this.saveBestSelling()
      ])
      await this.fetchDependencies();
      // setTimeout(()=>{
      //   window.location.reload();
      // },[2000])
    }
     catch(error){
    console.error("An error occurred:", error);
   }
  };

  handleSaveCmsPage = async()=>{
    const {couponBanner,heroBanner,primaryBanners,secondaryBanners,kuwaUSPs,socialMediaLinks,contactDetails,
      brand,healthCategories,collections,menuItemHealthGoals,menuBanner,menuItemCategories} = this.state;
      const {selectedCountry} = this.state
    
      const allEmpty = Object.keys(couponBanner).length==0 && Object.keys(heroBanner).length==0 && primaryBanners.length == 0 && secondaryBanners.length == 0
      && kuwaUSPs.length == 0 && socialMediaLinks.length == 0 && Object.keys(contactDetails).length==0 &&
      brand.length == 0 && healthCategories.length == 0 && collections.length == 0
      && menuItemHealthGoals.length ==0 && Object.keys(menuBanner).length==0 && menuItemCategories.length == 0

      console.log("allEmpty",allEmpty)
      
    const payload = 
     [{
      data:[couponBanner],
      rank: null,
      sectionHeading:"coupon_banner"
     },
     {
     data:[heroBanner],
     rank: null,
     sectionHeading:"hero_banner"
     },
     {
      data:primaryBanners,
      rank: null,
      sectionHeading:"primary_banner"
      },
      {
        data:secondaryBanners,
        rank: null,
        sectionHeading:"secondary_banners"
        },
        {
          data:kuwaUSPs,
          rank: null,
          sectionHeading:"kuwa_usp"
          },
          {
            data:socialMediaLinks,
            rank: null,
            sectionHeading:"social_media_links"
            },
            {
              data:[contactDetails],
              rank: null,
              sectionHeading:"contact_details"
              },
              {
                data:brand,
                rank: null,
                sectionHeading:"menu_items_brand_list"
                },
                {
                  data:healthCategories,
                  rank: null,
                  sectionHeading:"health_categories_list"
                  },
                  {
                    data:collections,
                    rank: null,
                    sectionHeading:"collection_best_seller"
                    },
                    {
                      data:menuItemHealthGoals,
                      rank: null,
                      sectionHeading:"menu_items_health_list"
                      },
                      {
                        data:[menuBanner],
                        rank: null,
                        sectionHeading:"menu_banners"
                        },
                        {
                          data:menuItemCategories,
                          rank: null,
                          sectionHeading:"menu_list_items"
                          }
     ]

    if(allEmpty){

  
    try{

    const res =   await API.post(`/cms/complete-cms/${selectedCountry.id}`,payload);
    console.log("saveCmsPage",res)
    this.showSuccessNotification("CMS page Created Successfully");


    }
    catch(error){
      console.log("error",error)
    }
  }
  else{
    try{

      const res =   await API.put(`/cms/complete-cms/${selectedCountry.id}`,payload);
      this.showSuccessNotification("CMS page Update Successfully");
  
  
      }
      catch(error){
        console.log("error",error)
      }
  }
  }

//   saveCouponBanner = async () => {
//     const { couponBanner } = this.state;
//     const config = {
//       method: couponBanner.id ? "PATCH" : "POST",
//       url: couponBanner.id
//         ? `/cms/coupon-banner/${couponBanner.id}/`
//         : "/cms/coupon-banner/",
//       data: couponBanner,
//     };

//     try {
//       await API.request(config);
//       this.showSuccessNotification("Coupon banner saved successfully!");
//     } catch (error) {
//       console.log("saveCouponBanner:: Error on saving coupon banner!", error);
//       this.showErrorNotification(
//         "Error on saving coupon banner! " + error.message ?? ""
//       );
//     }
//   };

//   saveHeroBanner = async () => {
//     const { heroBanner } = this.state;
//     const config = {
//       method: heroBanner.id ? "PATCH" : "POST",
//       url: heroBanner.id
//         ? `/cms/hero-banner/${heroBanner.id}/`
//         : "/cms/hero-banner/",
//       data: heroBanner,
//     };

//     try {
//       await API.request(config);
//       this.showSuccessNotification("Hero banner saved successfully!");
//     } catch (error) {
//       console.log("saveHeroBanner:: Error on saving hero banner!", error);
//       this.showErrorNotification(
//         "Error on saving hero banner! " + error.message ?? ""
//       );
//     }
//   };

  saveBestSelling = async (option) => {
    const { bestSelling ,selectedCountry} = this.state;
    const {selectedBestSellingCollectionOption} = this.state;
    const collectionId = selectedBestSellingCollectionOption?.id || ""
    console.log("bestSellingSave",bestSelling)
    bestSelling.forEach((it) => {
      if (it.is_new) {
        delete it.id;
        delete it.is_new;
      }
    });
    console.log("updateBestSelling",bestSelling)
    // const config = {
    //   method: "POST",
    //   url: "/cms/best-selling",
    //   data: bestSelling,
    // };
    const config = {
      method: "POST",
      url: `/cms/collectionBestSeller/${collectionId}?country=${selectedCountry.id}`,
      data: bestSelling,
    };
    if(collectionId){
    try {
      await API.request(config);
      this.showSuccessNotification("Best selling saved successfully!");
    } catch (error) {
      console.log("saveBestSelling:: Error on saving best selling!", error);
      this.showErrorNotification(
        "Error on saving best selling! " + error.message ?? ""
      );
    }
    finally{
     
    }
  }
  else{

  }
  };

//   savePrimaryBanners = async () => {
//     const { primaryBanners } = this.state;
//     if (primaryBanners && primaryBanners.length) {
//       primaryBanners.forEach((it) => {
//         if (it.is_new) {
//           delete it.id;
//           delete it.is_new;
//         }
//       });
//     }
//     const config = {
//       method: "POST",
//       url: "/cms/primary-banner",
//       data: primaryBanners,
//     };

//     try {
//       await API.request(config);
//       this.showSuccessNotification("Primary banners are saved successfully!");
//     } catch (error) {
//       console.log(
//         "savePrimaryBanners:: Error on saving primary banners!",
//         error
//       );
//       this.showErrorNotification(
//         "Error on saving primary banners! " + error.message ?? ""
//       );
//     }
//   };

//   saveSecondaryBanners = async () => {
//     const { secondaryBanners } = this.state;
//     if (secondaryBanners && secondaryBanners.length) {
//       secondaryBanners.forEach((it) => {
//         if (it.is_new) {
//           delete it.id;
//           delete it.is_new;
//         }
//       });
//     }
//     const config = {
//       method: "POST",
//       url: "/cms/secondary-banner",
//       data: secondaryBanners,
//     };

//     try {
//       await API.request(config);
//       this.showSuccessNotification("Secondary banners are saved successfully!");
//     } catch (error) {
//       console.log(
//         "saveSecondaryBanners:: Error on saving secondary banners!",
//         error
//       );
//       this.showErrorNotification(
//         "Error on saving secondary banners! " + error.message ?? ""
//       );
//     }
//   };

//   saveBrands = async () => {
//     const { brand } = this.state;
//     brand?.brand?.forEach((it) => {
//       if (it.is_new) {
//         delete it.id;
//         delete it.is_new;
//       }
//     });
//     const config = {
//       method: "POST",
//       url: "/cms/brand",
//       data: brand,
//     };

//     try {
//       await API.request(config);
//       this.showSuccessNotification("Brand saved successfully!");
//     } catch (error) {
//       console.log("saveBrands:: Error on saving brand!", error);
//       this.showErrorNotification(
//         "Error on saving brand! " + error.message ?? ""
//       );
//     }
//   };

//   saveHealthCategories = async () => {
//     const { healthCategories } = this.state;
//     if (healthCategories && healthCategories.length) {
//       healthCategories.forEach((it) => {
//         if (it.is_new) {
//           delete it.id;
//           delete it.is_new;
//         }
//       });
//     }
//     const config = {
//       method: "POST",
//       url: "/cms/health-category",
//       data: healthCategories,
//     };

//     try {
//       await API.request(config);
//       this.showSuccessNotification("Health categories are saved successfully!");
//     } catch (error) {
//       console.log(
//         "saveHealthCategories:: Error on saving health categories!",
//         error
//       );
//       this.showErrorNotification(
//         "Error on saving health categories! " + error.message ?? ""
//       );
//     }
//   };

//   saveBestSellingCollections = async () => {
//     const { collections } = this.state;
//     if (collections && collections.length) {
//       collections.forEach((it) => {
//         if (it.is_new) {
//           delete it.id;
//           delete it.is_new;
//         }
//       });
//     }
//     const config = {
//       method: "POST",
//       url: "/cms/collectionBestSeller",
//       data: collections,
//     };

//     try {
//       await API.request(config);
//       this.showSuccessNotification("BestSelling Collections are saved successfully!");
//     } catch (error) {
//       console.log(
//         "saveBestCellingCollections:: Error on saving BestSelling Collections!",
//         error
//       );
//       this.showErrorNotification(
//         "Error on saving health categories! " + error.message ?? ""
//       );
//     }
//   }

//   saveMenuBanner = async () => {
//     const { menuBanner } = this.state;
//     if(menuBanner.redirectionLink && menuBanner.image){


    
    
//    const  method=  menuBanner.id ? "PATCH" : "POST";
//     const config = {
//       method:method,
//       url: menuBanner.id
//         ? `/cms/menu-banner/${menuBanner.id}/`
//         : "/cms/menu-banner/",
//       data: method === "POST" ? [menuBanner] : menuBanner,
//     };

//     try {
//       await API.request(config);
//       this.showSuccessNotification("Menu banner saved successfully!");
//     } catch (error) {
//       console.log("saveHeroBanner:: Error on saving menu banner!", error);
//       this.showErrorNotification(
//         "Error on saving menu banner! " + error.message ?? ""
//       );
//     }
//   };
// }


//   saveMenuItemHealthGoals = async () => {
//     const { menuItemHealthGoals } = this.state;
//     if (menuItemHealthGoals && menuItemHealthGoals.length) {
//       menuItemHealthGoals.forEach((it) => {
//         if (it.is_new) {
//           delete it.id;
//           delete it.is_new;
//         }
//       });
//     }
//     const config = {
//       method: "POST",
//       url: "/cms/menu-items/health",
//       data: menuItemHealthGoals,
//     };
//     console.log("exploreCategory",menuItemHealthGoals)
//     try {
//       await API.request(config);
//       this.showSuccessNotification(
//         "Menu item health goals are saved successfully!"
//       );
//     } catch (error) {
//       console.log(
//         "saveMenuItemHealthGoals:: Error on saving menu item health goals!",
//         error
//       );
//       this.showErrorNotification(
//         "Error on saving menu item health goals! " + error.message ?? ""
//       );
//     }
//   };

//   saveMenuItemBrands = async () => {
//     const { menuItemBrands } = this.state;
//     if (menuItemBrands && menuItemBrands.length) {
//       menuItemBrands.forEach((it) => {
//         if (it.is_new) {
//           delete it.id;
//           delete it.is_new;
//         }
//       });
//     }
//     const config = {
//       method: "POST",
//       url: "/cms/menu-items/brand",
//       data: menuItemBrands,
//     };

//     try {
//       await API.request(config);
//       this.showSuccessNotification("Menu item brands are saved successfully!");
//     } catch (error) {
//       console.log(
//         "saveMenuItemBrands:: Error on saving menu item brands!",
//         error
//       );
//       this.showErrorNotification(
//         "Error on saving menu item brands! " + error.message ?? ""
//       );
//     }
//   };

//   saveMenuItemCategories = async () => {
//     const { menuItemCategories } = this.state;
//     if (menuItemCategories && menuItemCategories.length) {
//       menuItemCategories.forEach((it) => {
//         if (it.is_new) {
//           delete it.id;
//           delete it.is_new;
//         }
//       });
//     }
//     const config = {
//       method: "POST",
//       url: "/cms/menu-list-items",
//       data: menuItemCategories,
//     };

//     try {
//       await API.request(config);
//       this.showSuccessNotification("Menu list items are saved successfully!");
//     } catch (error) {
//       console.log(
//         "saveMenuItemBrands:: Error on saving menu list items",
//         error
//       );
//       this.showErrorNotification(
//         "Error on saving menu list items! " + error.message ?? ""
//       );
//     }
//   };

//   saveKuwaUSP = async () => {
//     const { kuwaUSPs } = this.state;
//     console.log("saveKuwaUSP", { kuwaUSPs });
//     if (kuwaUSPs && kuwaUSPs.length) {
//       kuwaUSPs.forEach((it) => {
//         if (it.is_new) {
//           delete it.id;
//           delete it.is_new;
//         }
//       });
//     }
//     const config = {
//       method: "POST",
//       url: "/cms/kuwa-usp",
//       data: kuwaUSPs,
//     };

//     try {
//       await API.request(config);
//       this.showSuccessNotification("Kuwa usps are saved successfully!");
//     } catch (error) {
//       console.log("saveKuwaUSP:: Error on saving kuwa usps!", error);
//       this.showErrorNotification(
//         "Error on saving kuwa usps! " + error.message ?? ""
//       );
//     }
//   };

//   saveSocialMediaLinks = async () => {
//     const { socialMediaLinks } = this.state;
//     if (socialMediaLinks && socialMediaLinks.length) {
//       socialMediaLinks.forEach((it) => {
//         if (it.is_new) {
//           delete it.id;
//           delete it.is_new;
//         }
//       });
//     }
//     const config = {
//       method: "POST",
//       url: "/cms/social-media",
//       data: socialMediaLinks,
//     };

//     try {
//       await API.request(config);
//       this.showSuccessNotification(
//         "Social media links are saved successfully!"
//       );
//     } catch (error) {
//       console.log(
//         "saveSocialMediaLinks:: Error on saving social media links!",
//         error
//       );
//       this.showErrorNotification(
//         "Error on saving social media links! " + error.message ?? ""
//       );
//     }
//   };

//   saveContactDetail = async () => {
//     const { contactDetail } = this.state;
//     if (!contactDetail.id) {
//       contactDetail.isActive = true;
//     }

//     const config = {
//       method: contactDetail.id ? "PATCH" : "POST",
//       url: contactDetail.id
//         ? `/cms/contact-detail/${contactDetail.id}/`
//         : "/cms/contact-detail/",
//       data: contactDetail,
//     };

//     try {
//       await API.request(config);
//       this.showSuccessNotification("Contact detail saved successfully!");
//     } catch (error) {
//       console.log("saveContactDetail:: Error on saving contact detail!", error);
//       this.showErrorNotification(
//         "Error on saving contact detail! " + error.message ?? ""
//       );
//     }
//   };

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  renderPageHeader = () => {
    return (
      <div class="d-flex justify-content-start-0" style={{ position: "sticky", top: "50px", zIndex: "999",height:"64px",paddingTop:"11px",background:"#FFF",width:"102%",paddingRight:"5px" }}>
        
        <div style={{ alignSelf: "flex-start" }}>
          <Button
            onClick={() => this.props.history.goBack()}
            
            color="inherit"
            size="large"
          >
            <ArrowBackIcon />
          </Button>
        </div>
        <span
          className="mx-3 my-2"
          style={{ fontWeight: "600", fontSize: "1.25rem" }}
        >
          CMS (Content Management System)
        </span>
        
        <div >
          <Select
          value={this.state.selectedCountry}
          onChange={(countries)=>{
            this.setState({ selectedCountry: countries})
          }}
          options ={this.state.countries}
          />

        </div>
       

        <div className="col text-end">
          <Button
            onClick={this.handleSaveCMS}
            variant="contained"
            size="large"
            sx={{
              bgcolor: "#247A81",
              color: "white",
              borderRadius: "20px",
              ":hover": {
                bgcolor: "#125443",
              },
              textTransform: "capitalize",
            }}
          >
            Save
          </Button>
        </div>
      </div>
    );
  };

  renderCouponBannerCard = () => {
    const { couponBanner = {} } = this.state;
    return (
      <Card style={{ background: "#FFFFFF" }}>
        <Card.Body>
          <div className="row">
            <div className="col" style={{display:"flex",gap:"10px"}}>
              <p className="h6">Coupon banner</p>
              <div>
              <Toggle className="col"
                checked={couponBanner.isActive}
                onChange={(e)=>{
                  this.setState({
                    couponBanner: {
                   ...couponBanner,
                      isActive: e.target.checked,
                    },
                  })
                }}
              />
            </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <label htmlFor="bannerText">Banner text</label>
              <input
                type="text"
                value={couponBanner?.bannerText ?? ""}
                onChange={(event) => {
                  this.setState({
                    couponBanner: {
                      ...couponBanner,
                      bannerText: event.target.value,
                    },
                  });
                }}
                placeholder="Banner text"
                id="bannerText"
                name="bannerText"
                className="form-control"
              />
            </div>
            <div className="col">
              <label htmlFor="bannerTextArabic">Banner text (Arabic)</label>
              <input
                type="text"
                value={couponBanner?.bannerTextArabic ?? ""}
                onChange={(event) => {
                  this.setState({
                    couponBanner: {
                      ...couponBanner,
                      bannerTextArabic: event.target.value,
                    },
                  });
                }}
                placeholder="Banner text (Arabic)"
                id="bannerTextArabic"
                name="bannerTextArabic"
                className="form-control"
              />
            </div>
          
          </div>
          
          <div className="row mt-2">
          <div className="col">
              <label htmlFor="buttonText">Button text</label>
              <input
                type="text"
                value={couponBanner?.buttonText ?? ""}
                onChange={(event) => {
                  this.setState({
                    couponBanner: {
                      ...couponBanner,
                      buttonText: event.target.value,
                    },
                  });
                }}
                placeholder="Button text"
                id="buttonText"
                name="buttonText"
                className="form-control"
              />
            </div>
            <div className="col">
              <label htmlFor="buttonTextArabic">Button text (Arabic)</label>
              <input
                type="text"
                value={couponBanner?.buttonTextArabic ?? ""}
                onChange={(event) => {
                  this.setState({
                    couponBanner: {
                      ...couponBanner,
                      buttonTextArabic: event.target.value,
                    },
                  });
                }}
                placeholder="Button text (Arabic)"
                id="buttonTextArabic"
                name="buttonTextArabic"
                className="form-control"
              />
            </div>
            </div>
          
          
          <div className="row mt-2">
          <div className="col">
              <label htmlFor="colorHexCode">Color Code (HEX)</label>
              <input
                type="text"
                value={couponBanner?.colorHexCode ?? ""}
                onChange={(event) => {
                  this.setState({
                    couponBanner: {
                      ...couponBanner,
                      colorHexCode: event.target.value,
                    },
                  });
                }}
                placeholder="Color Code (HEX)"
                id="colorHexCode"
                name="colorHexCode"
                className="form-control"
              />
            </div>
            <div className="col">
              <label htmlFor="redirectionLink">Redirection Link</label>
              <input
                type="text"
                value={couponBanner?.redirectionLink ?? ""}
                onChange={(event) => {
                  this.setState({
                    couponBanner: {
                      ...couponBanner,
                      redirectionLink: event.target.value,
                    },
                  });
                }}
                placeholder="Redirection Link"
                id="redirectionLink"
                name="redirectionLink"
                className="form-control"
              />
            </div>

          </div>
        </Card.Body>
      </Card>
    );
  };

  renderHeroBannerCard = () => {
    const { heroBanner = {} } = this.state;
    console.log("heroBanner",heroBanner)
    return (
      <Card style={{ background: "#FFFFFF" }}>
        <Card.Body>
          <div className="row">
            <div className="col" style={{display:"flex",gap:"10px"}}>
              <p className="h6">Hero banner</p>
              <div>
              <Toggle className="col"
                checked={heroBanner.isActive}
                onChange={(e)=>{
                  this.setState({
                    heroBanner: {
                   ...heroBanner,
                      isActive: e.target.checked,
                    },
                  })
                }}
              />
            </div>
            </div>
           
            <div className="col text-end">
              <Form.Check
                checked={heroBanner.type?.toLowerCase() === "image"}
                onChange={(event) => {
                  const isChecked = event.target.checked;
                  const updatedHeroBanner = {
                    ...this.state.heroBanner,
                    type: isChecked ? "IMAGE" : "VIDEO",
                  };
                  this.setState({ heroBanner: updatedHeroBanner });
                }}
                inline
                label="Image"
                name="heroBannerType"
                type="checkbox"
                id="checkbox-image"
                size="lg"
              />
              <Form.Check
                checked={heroBanner.type?.toLowerCase() === "video"}
                onChange={(event) => {
                  const isChecked = event.target.checked;
                  const updatedHeroBanner = {
                    ...this.state.heroBanner,
                    type: isChecked ? "VIDEO" : "IMAGE",
                  };
                  this.setState({ heroBanner: updatedHeroBanner });
                }}
                inline
                label="Video"
                name="heroBannerType"
                type="checkbox"
                id="checkbox-video"
                size="lg"
              />
            </div>
          </div>
          {heroBanner.type?.toLowerCase() === "video" && (
            <>
              <div className="row mt-2">
                <div className="col">
                  <label htmlFor="videoRedirectionLink">Redirection Link</label>
                  <input
                    type="text"
                    value={heroBanner?.videoRedirectionLink ?? ""}
                    onChange={(event) => {
                      this.setState({
                        heroBanner: {
                          ...heroBanner,
                          videoRedirectionLink: event.target.value,
                        },
                      });
                    }}
                    placeholder="Redirection Link"
                    id="videoRedirectionLink"
                    name="videoRedirectionLink"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6">
                  <label htmlFor="mobileVideo">Mobile Banner</label>
                  <input
                    type="text"
                    value={heroBanner?.mobileVideo ?? ""}
                    onChange={(event) => {
                      this.setState({
                        heroBanner: {
                          ...heroBanner,
                          mobileVideo: event.target.value,
                        },
                      });
                    }}
                    placeholder="Mobile Banner"
                    id="mobileVideo"
                    name="mobileVideo"
                    className="form-control"
                  />
                </div>
                <div className="col-6">
                  <label htmlFor="desktopVideo">Desktop Banner</label>
                  <input
                    type="text"
                    value={heroBanner?.desktopVideo ?? ""}
                    onChange={(event) => {
                      this.setState({
                        heroBanner: {
                          ...heroBanner,
                          desktopVideo: event.target.value,
                        },
                      });
                    }}
                    placeholder="Desktop Banner"
                    id="desktopVideo"
                    name="desktopVideo"
                    className="form-control"
                  />
                </div>
              </div>
            </>
          )}
          {heroBanner.type?.toLowerCase() === "image" && (
            <>
              <div className="row mt-2">
                <div className="col">
                  <label htmlFor="imageRedirectionLink">Redirection Link</label>
                  <input
                    type="text"
                    value={heroBanner?.imageRedirectionLink ?? ""}
                    onChange={(event) => {
                      this.setState({
                        heroBanner: {
                          ...heroBanner,
                          imageRedirectionLink: event.target.value,
                        },
                      });
                    }}
                    placeholder="Redirection Link"
                    id="imageRedirectionLink"
                    name="imageRedirectionLink"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6">
                  <label htmlFor="mobileImage">Mobile Banner</label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={this.handleImageFileSelect}
                    id="mobileImage"
                    name="mobileImage"
                    className="form-control"
                  />
                </div>
                <div className="col-6">
                  <label htmlFor="desktopImage">Desktop Banner</label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={this.handleImageFileSelect}
                    id="desktopImage"
                    name="desktopImage"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="form-group col-6">
                  {heroBanner?.mobileImage && (
                    <img
                      id="selectedMobileImage"
                      name="selectedMobileImage"
                      src={heroBanner.mobileImage}
                      alt="Selected"
                      className="img-thumbnail mx-auto"
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </div>
                <div className="form-group col-6">
                  {heroBanner?.desktopImage && (
                    <img
                      id="selectedDesktopImage"
                      name="selectedDesktopImage"
                      src={heroBanner.desktopImage}
                      alt="Selected"
                      className="img-thumbnail mx-auto"
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          )}
          <div className="row mt-2">
            <div className="col">
              <label htmlFor="colorHexCode">Color</label>
              <input
                type="text"
                value={heroBanner?.colorHexCode ?? ""}
                onChange={(event) => {
                  this.setState({
                    heroBanner: {
                      ...heroBanner,
                      colorHexCode: event.target.value,
                    },
                  });
                }}
                placeholder="Hex Code"
                id="colorHexCode"
                name="colorHexCode"
                className="form-control"
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  renderBestSellingCard = () => {
    const { products, bestSelling } = this.state;
   

    return (
      <Card style={{ background: "#FFFFFF", marginTop: "-24px" }}>
        <Card.Body style={{background:"#F1FAF8"}}>
          <div className="row mt-2">
            <div className="col">
              <p className="h6">Products</p>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <BestSellingProductsTable
                products={products ?? []}
                // bestSellingProducts={bestSelling?.products ?? []}
                bestSellingProducts={bestSelling ?? []}
                onProductsUpdated={this.handleBestSellingProductsUpdated}
                handleProductOption={(option)=>this.fetchProductsCollectionWise(option)}
                selectedBestSellingCollectionOption={this.state.selectedBestSellingCollectionOption}
                collectionOptions={this.state.collections}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  renderPrimaryBannersCard = () => {
    const { primaryBanners } = this.state;
    return (
      <Card style={{ background: "#FFFFFF" }}>
        <Card.Body>
          <div className="row">
            <div className="col">
              <p className="h6">Primary banner</p>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <PrimaryBannersTable
                banners={primaryBanners ?? []}
                onBannersUpdated={this.handlePrimaryBannersUpdated}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  renderSecondaryBannersCard = () => {
    const { secondaryBanners } = this.state;
    return (
      <Card style={{ background: "#FFFFFF" }}>
        <Card.Body>
          <div className="row">
            <div className="col">
              <p className="h6">Secondary banner</p>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <SecondaryBannersTable
                banners={secondaryBanners ?? []}
                onBannersUpdated={this.handleSecondaryBannersUpdated}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  renderBrandCard = () => {
    const { brand, productTypes, productTypeBrands ,productTypeBrandsIncludeInActive} = this.state;

    return (
      <Card style={{ background: "#FFFFFF" }}>
        <Card.Body>
          <div className="row">
            <div className="col">
              <p className="h6">Brand</p>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <label htmlFor="brand-heading">Brand headline*</label>
              <input
                type="text"
                value={brand.heading ?? ""}
                onChange={(event) => {
                  this.setState({
                    brand: { ...brand, heading: event.target.value },
                  });
                }}
                placeholder="Brand headline"
                name="brand-heading"
                id="brand-heading"
                className="form-control"
              />
            </div>
            <div className="col">
              <label htmlFor="brand-heading-arabic">Brand headline (Arabic)*</label>
              <input
                type="text"
                value={brand.headingArabic ?? ""}
                onChange={(event) => {
                  this.setState({
                    brand: { ...brand, headingArabic: event.target.value },
                  });
                }}
                placeholder="Brand headline"
                name="brand-heading-arabic"
                id="brand-heading-arabic"
                className="form-control"
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <BrandsTable
              productTypesAll = {productTypeBrandsIncludeInActive ?? []}
                productTypes={productTypeBrands ?? []}
                brands={brand?.brand ?? []}
                onBrandsUpdated={this.handleBrandsUpdated}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  renderHealthCategoriesCard = () => {
    const { healthCategories, productTypes } = this.state;
    
    return (
      <Card style={{ background: "#FFFFFF" }}>
        <Card.Body>
          <div className="row">
            <div className="col">
              <p className="h6">Health Goals</p>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <HealthCategoriesTable
                productTypes={productTypes ?? []}
                healthCategories={healthCategories ?? []}
                onHealthCategoriesUpdated={this.handleHealthCategoriesUpdated}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };
  renderBestSellingCollectionCard = ()=>{

    const { collections, productTypes,productTypesIncludeInActive } = this.state;
    console.log("babha",collections)
    return(
      <Card style={{ background: "#FFFFFF" }}>
          <Card.Body>
          <div className="row">
            <div className="col">
              <p className="h6">Best Seller Section</p>
            </div>
          </div>
          
           <div className="row mt-2" >
            <div className="col">
              <label htmlFor="bestSellerSectionHeading">Heading*</label>
              <input
  type="text"
  value={collections.length > 0 ? collections[0].heading : ""}
  onChange={(event) => {
    const updatedCollections = collections.map((data) => ({
      ...data,
      heading: event.target.value,
    }));

    this.setState({
      collections: updatedCollections,
    });
  }}
  placeholder="Best Seller Heading"
  name="bestSellerSectionHeading"
  id="bestSellerSectionHeading"
  className="form-control"
/>


            </div>
            <div className="col">
              <label htmlFor="bestSellerSectionHeadingArabic">Heading (Arabic)*</label>
              <input
  type="text"
  value={collections.length > 0 ? collections[0].headingArabic : ""}
  onChange={(event) => {
    const updatedCollections = collections.map((data) => ({
      ...data,
      headingArabic: event.target.value,
    }));

    this.setState({
      collections: updatedCollections,
    });
  }}
  placeholder="Best Seller Heading (Arabic)"
  name="bestSellerSectionHeadingArabic"
  id="bestSellerSectionHeadingArabic"
  className="form-control"
/>


            </div>
          </div>
    <div className="row mt-2">
            <div className="col">
              <BestSellingCollectionTable
              ProductTypeAll={productTypesIncludeInActive ?? []}
              saveBestSellingCollections={this.saveBestSellingCollections}
                productTypes={productTypes ?? []}
                collections={collections ?? []}
                onCollectionsUpdated={this.handleBestSellingCollectionUpdate}
              />
            </div>
          </div>
          </Card.Body>
          </Card>
    )

  }

  renderMenuItemsCard = () => {
    const {
      menuBanner,
      menuItemBrands,
      menuItemCategories,
      menuItemHealthGoals,
      productTypes,
      productTypeBrands,
      productTypeHealthGoals,
      productTypeHealthGoalsIncludeInActive
    } = this.state;
    return (
      <>
      <Card style={{ background: "#FFFFFF" }}>
        <Card.Body>
          <>
          <div className="row">
            <div className="col">
              <p className="h6">Menu items</p>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <label htmlFor="menuBannerImage">Menu banner *</label>
              <input
                type="file"
                accept="image/*"
                onChange={this.handleMenuBannerImageFileSelect}
                name="menuBannerImage"
                id="menuBannerImage"
                className="form-control"
              />
                {((!this.state.menuBanner.image)  && this.state.menuBanner.redirectionLink) ? <span style={{color:"red"}}>Image is required</span> : ""}
            </div>
            {menuBanner?.image && (
              <div className="form-group col-6">
                <label htmlFor="menuBannerImage">Menu banner preview</label>
                <div>
                  <img
                    id="selectedMenuBannerImage"
                    name="selectedMenuBannerImage"
                    src={menuBanner.image}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
               
              </div>
            )}
          </div>
          <div className="row mt-2">
            <div className="col">
              <label htmlFor="menuBannerRedirectionLink">
                Redirection Link *
              </label>
              <input
                type="text"
                value={menuBanner?.redirectionLink ?? ""}
                onChange={(event) => {
                  this.setState({
                    menuBanner: {
                      ...menuBanner,
                      redirectionLink: event.target.value,
                    },
                  });
                }}
                placeholder="Redirection Link"
                id="menuBannerRedirectionLink"
                name="menuBannerRedirectionLink"
                className="form-control"
              />
              {console.log("first",this.state.menuBanner.image)}
             {(this.state.menuBanner.image && !this.state.menuBanner.redirectionLink) ? <span style={{color:"red"}}>Redirection link is required</span> : ""}
            </div>
          </div>
          <hr />
          <div className="row mt-2">
            <div className="col">
              <MenuTableCategories
                productTypes={productTypes ?? []}
                categories={menuItemCategories}
                onCategoriesUpdated={this.handleMenuItemCategoriesUpdated}
              />
            </div>
          </div>
          </>
          </Card.Body>
          </Card>
                <br /><br/>
          <Card>
            <Card.Body>
                <>
          <div className="row mt-2">
            <div className="col">
              <MenuTableHealthGoals
                productTypesAll={productTypeHealthGoalsIncludeInActive ?? []}
                productTypes={productTypeHealthGoals ?? []}
                healthGoals={menuItemHealthGoals}
                onHealthGoalsUpdated={this.handleMenuItemHealthGoalsUpdated}
              />
            </div>
          </div>
          <hr />
          <div className="row mt-2">
            <div className="col">
              {/* <MenuTableBrands
                productTypes={productTypeBrands ?? []}
                brands={menuItemBrands ?? []}
                onBrandsUpdated={this.handleMenuItemBrandsUpdated}
              /> */}
            </div>
          </div>
          </>
        </Card.Body>
      </Card>
      </>
    );
  };

  handleContactDetailChange = ({ currentTarget: input }) => {
    const contactDetail = { ...this.state.contactDetail };
    contactDetail[input.name] = input.value;
    this.setState({ contactDetail });
  };

  renderKuwaUSPCard = () => {
    const { kuwaUSPs } = this.state;
    return (
      <Card style={{ background: "#FFFFFF" }}>
        <Card.Body>
          <div className="row">
            <div className="col">
              <p className="h6">Get Kuwa USP</p>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <KuwaUSPTable
                kuwaUSPs={kuwaUSPs ?? []}
                onKuwaUSPsUpdated={this.handleKuwaUSPsUpdated}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  renderContactDetailCard = () => {
    const { contactDetail = {} } = this.state;

    return (
      <Card style={{ background: "#FFFFFF" }}>
        <Card.Body>
          <div className="row">
            <div className="col">
              <p className="h6">Contact Details</p>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <label htmlFor="companyAddress">Company Address</label>
              <textarea
                value={contactDetail.companyAddress ?? ""}
                onChange={this.handleContactDetailChange}
                placeholder="Company Address"
                id="companyAddress"
                name="companyAddress"
                className="form-control"
              ></textarea>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <label htmlFor="contactNumber">Contact Number</label>
              <input
                type="text"
                value={contactDetail.contactNumber ?? ""}
                onChange={this.handleContactDetailChange}
                placeholder="Contact Number"
                id="contactNumber"
                name="contactNumber"
                className="form-control"
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <label htmlFor="emailId">Email ID</label>
              <input
                type="email"
                value={contactDetail.emailId ?? ""}
                onChange={this.handleContactDetailChange}
                placeholder="Email ID"
                id="emailId"
                name="emailId"
                className="form-control"
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  renderSocialMediaLinksCard = () => {
    const { socialMediaLinks } = this.state;
    return (
      <Card style={{ background: "#FFFFFF" }}>
        <Card.Body>
          <div className="row">
            <div className="col">
              <p className="h6">Social Media Links</p>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <SocialMediaLinksTable
                socialMediaLinks={socialMediaLinks ?? []}
                onSocialMediaLinksUpdated={this.handleSocialMediaLinksUpdated}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  render() {
    const pageHeader = this.renderPageHeader();
    const pageLeftSection = (
      <div className="col-7" style={{ marginRight: "16px" }}>
        {this.renderCouponBannerCard()}
        {this.renderHeroBannerCard()}
        {this.renderPrimaryBannersCard()}
        {this.renderBestSellingCollectionCard()}
        {this.renderBestSellingCard()}
        {this.renderSecondaryBannersCard()}
        {this.renderBrandCard()}
        {this.renderHealthCategoriesCard()}
        {this.renderMenuItemsCard()}
       
        {/* <HeroBannerCard
          heroBanner={heroBanner}
          onUpdate={(updatedHeroBanner) =>
            this.setState({ heroBanner: updatedHeroBanner })
          }
        /> */}
      </div>
    );
    const pageRightSection = (
      <div className="col-5">
        <div className="d-flex flex-column">
          {this.renderKuwaUSPCard()}
          {this.renderContactDetailCard()}
          {this.renderSocialMediaLinksCard()}
        </div>
      </div>
    );
    const pageBody = (
      <div class="d-flex justify-content-between mt-4">
        {pageLeftSection}
        {pageRightSection}
      </div>
    );

    return (
      <div className="container-fluid">
        {pageHeader}
        {pageBody}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteCMS);
