import React, { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import { Modal } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import API from "../../../utils/API";
import Spinner from "../../../components/Spinner/Spinner";
import { connect } from "react-redux";
import './product-search.scss';


const fetchProducts = async (searchTerm = "", countryId="" ,) => {
    try {
    //   this.showLoading();
      const url = `module/search/order/product`;
      const { data: products = [] } = await API.get(url, {
        params: { country: countryId?.id , key:searchTerm},
      });
   
      return products;
      // this.setState({ products }, () => this.createProductOptions());
    } catch (error) {
      console.log("Error on fetching products!", error);
    } finally {
    //   this.hideLoading();
    }
  };




const ListOfAllProducts = ({selectedProduct={},product={},countryId={},index=0,setSelectedProduct={}}) => {
  console.log("countryId",countryId)
    const { id, descriptions, images, availabilities } = product && product.product || {};
    const variantData = product && product.variants || [];
        const description =
          descriptions && descriptions.length ? descriptions[0] : null;
        const imageUrl =
          images && images.length ? images[0].productImageUrl ?? "" : "";
        const availability =
          availabilities && availabilities.length ? availabilities[0] : null;
        const priceInfo =
          availability && availability.prices && availability.prices.length
            ? availability?.prices?.filter(data=> data.country === countryId.id)
            : null;
console.log("priceInfo",priceInfo)
    const onSelect = ({product,variantData}) => {
        // console.log("+productproductproduct",product)
        // console.log("+productproductproductvariantDatavariantData",variantData);
       let data = {
            productId:"",
            variantId:"",
            isVariant:"",
            quantity:1
        }
        if(product){
            data = {
                productId:product.product.id,
                variantId:"null",
                isVariant:false,
                quantity:1
            }
        }else if(variantData){
            data = {
                productId:variantData.variants.productId,
                variantId:variantData.variants.id,
                isVariant:true,
                quantity:1
            }
        }
        setSelectedProduct(data);
    }

   
    // console.log("selectedProduct.productId",selectedProduct.productId)
    // console.log("idid",id)
    return (
      <Table   hover size="lg"  style={{marginBottom:'0px !important'}}>
        <tbody>
          <tr onClick={()=>onSelect({product:product})}>
            <td colSpan={2} style={{width:'5%',textAlign:'center'}}>
                <input disabled={availability?.onHandQuantity <=0} type="checkbox" checked={ selectedProduct.productId && selectedProduct.productId == id && !selectedProduct.isVariant }/>
            </td>
            <td colSpan={2} style={{width:'5%',textAlign:'center'}}>
                <img style={{width:'50px',height:'50px',objectFit:'contain'}} src={imageUrl} alt="product-img"/>
            </td>
            <td colSpan={2} style={{width:'30%',textAlign:'left'}}>{description?.name ?? ""}</td>
           {availability?.onHandQuantity <=0 && <td colSpan={1} style={{width:'20%',textAlign:'left',color:"red"}}>Out of stock</td>}

            <td colSpan={2} style={{width:'20%',textAlign:'right'}}>{`${availability?.onHandQuantity ?? 0} available`}</td>
            <td colSpan={2} style={{width:'20%',textAlign:'right'}}>{`${countryId?.currency ?? ""} ${priceInfo[0]?.productPriceSpecialAmount ?? 0}`}</td>
          </tr>
            {
                variantData.map((data,index)=>{
                    // console.log("pricingg",data.pricings);

                    if(data.pricings && data.pricings.length > 0){
                      const {image="",name="", quantity=0 } = data && data.variants || {};
                      const {finalPrice=0 } = data && data.pricings[0] || {};
                      const variantIdd = data && data.pricings && data.pricings.length>0 && data.pricings[0].variantId;
                          return(
                              <tr onClick={()=>onSelect({variantData:data})}>
                                  <td colSpan={4} style={{width:'10%',textAlign:'center'}}>
                                      <input disabled={quantity <=0} type="checkbox" checked={selectedProduct && selectedProduct.variantId == variantIdd  }/>
                                  </td>
                                  <td colSpan={2} style={{width:'50%',textAlign:'left'}}>{"Pack of "+name}</td>
                                  {quantity <=0 && <td colSpan={2} style={{width:'20%',textAlign:'left',color:"red"}}>Out of stock</td>}
                                  <td colSpan={2} style={{width:'20%',textAlign:'right'}}>{`${quantity ?? 0} available`}</td>
                                  <td colSpan={3} style={{width:'20%',textAlign:'right'}}>{`${countryId?.currency ?? ""} ${finalPrice}`}</td>
                            </tr>
                          )
                    }else{
                      return
                    }
                    
                })
            }
        </tbody>
      </Table>
    );
  }




const ProductSearchModal = ({countryId="",searchTerm="",showProductSearchModal,setShowProductSearchModal,handleProductSelect}) => {

    // console.log("searchTermsearchTerm",searchTerm)

    const [searchTxt , setSearchTxt] = useState(searchTerm);
    const [ listOfAllProduct , setListOfAllProduct] = useState([]);
    const [ isSearching , setIsSearching] = useState(false);
    const [ selectedProduct , setSelectedProduct] = useState([]);

    useEffect(()=>{
        setSearchTxt(searchTerm);
       
    },[searchTerm]);

    // useEffect(()=>{
    //     getProduct(searchTxt)
    // },[searchTxt])


    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        getProduct(searchTxt);
      }
    };
 

    const getProduct = async (searchTxt) => {
        setIsSearching(true)
       const data = await fetchProducts(searchTxt,countryId);
       console.log("")
       setIsSearching(false)
       setListOfAllProduct(data)
    }

    // console.log("selectedProductselectedProduct",selectedProduct)

    // console.log("listOfAllProductlistOfAllProduct",listOfAllProduct)

    return(
            <Modal  show={showProductSearchModal} onHide={()=>setShowProductSearchModal(!showProductSearchModal)} size="lg" centered>
              <Modal.Header>
                <div>All products</div>
              </Modal.Header>
              <Modal.Body>
                {
             
                 <>
                    <div className="row">
                        <div className="form-group col">
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    value={searchTxt ?? ""}
                                    onChange={(e) =>{setSearchTxt(e.target.value)}}
                                    onKeyPress={handleKeyPress}
                                    id="search-bar"
                                    name="search-bar"
                                    className="form-control"
                                    placeholder="Search Products"
                                    style={{borderRadius:'4px !important'}}
                                    autoFocus
                                />
                            </div>
                        </div>
                    </div>
                    {
                    (isSearching)? <div style={{height:'50vh',overflow:'scroll'}}>
                           <div className="search-spinner" ></div>
                       </div>:(!isSearching && listOfAllProduct && listOfAllProduct.length ==0)? <div>No results found for "{searchTxt}"</div>:
                        <div style={{height:'50vh',overflow:'scroll'}}>
                            {
                                listOfAllProduct && listOfAllProduct.map((data,index)=>{
                                    // console.log("Datatat",data)
                                    
                                    return <ListOfAllProducts selectedProduct={selectedProduct} product={data} countryId={countryId} index={index} setSelectedProduct={setSelectedProduct}/>
                                })
                            }
                        </div>
                    }
                   
                </> }
              </Modal.Body>
              <Modal.Footer>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'100%'}}>
                    <button 
                    onClick={()=>{}}
                    type="button"
                    className="btn btn-sm btn-outline-secondary "
                    style={{opacity:0,borderRadius:"20px"}}
                    >
                    3 variants selected
                    </button>
                    <div style={{display:'flex',justifyContent:'space-between',gap:'20px'}}>
                        <button style={{borderRadius:"20px"}}
                        onClick={()=>setShowProductSearchModal(!showProductSearchModal)}
                        type="button"
                        className="btn btn-sm btn-outline-secondary"
                        >
                        Cancel
                        </button>
                        <button 
                        onClick={(event) => handleProductSelect(selectedProduct)}
                        type="button"
                        className="btn btn-sm btn-success ml-2"
                        style={(Object.keys(selectedProduct).length>0)?{}:{opacity:0.2,backgroundColor:'#247A81'}}
                        >
                        Add
                        </button>
                    </div>
                </div>
            
              </Modal.Footer>
            </Modal>
    )
}



const ProductSearch = ({countryId="",handleProductSelect={},showNotificationMessage={}}) => {
    const [searchTerm , setSearchTerm ] = useState("");
    const [ showProductSearchModal , setShowProductSearchModal] = useState(false);

    // console.log("searchTermsearchTerm",searchTerm)



   const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);

  const showNotification = (notificationMessage, isSuccessMessage) =>
    showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });
  return (
    <>
    <Card style={{ background: "#FFFFFF", }}>
        <Card.Body style={{borderRadius:'14px'}}>
          <div className="row">
            <div className="col">
              <p className="h6">Products</p>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
                <div className="input-group mb-3">
                    <input
                        type="text"
                        value={searchTerm ?? ""}
                        onChange={(e) =>{
                          
                          if(!countryId){
                            showErrorNotification("Please Select Country!");
                           
                          }
                           
                          else{
                            setSearchTerm(e.target.value);
                            setShowProductSearchModal(true)
                          }
                          
                        
                        }}
                        id="search-bar"
                        name="search-bar"
                        className="form-control"
                        placeholder="Search Products"
                        style={{borderRadius:'4px !important'}}
                        autoComplete="off"
                    />
                </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <ProductSearchModal handleProductSelect={(data)=>{handleProductSelect(data);setShowProductSearchModal(false)}} countryId={countryId} searchTerm={searchTerm} showProductSearchModal={showProductSearchModal} setShowProductSearchModal={setShowProductSearchModal} />
    </>
  );
}


function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearch);

