import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Dropdown } from "react-bootstrap";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NotesCard from "./NotesCard";
import EditContactInfoViewModal from "./EditContactInfoViewModal";
import EditNotesViewModal from "./EditNotesViewModal";
import ManageAddressViewModal from "./ManageAddressViewModal";
import { titleCase } from "../../utils/common";
import API from "../../utils/API";
import { withRouter } from "react-router-dom";


import { formatDateTime } from "../../utils/common";
import EditBillingAddressModel from "./EditBillingAddressModel";
import EditShippingAddressModel from "./EditShippingAddressModel";
// import EditMarketingSettings from "./EditMarketingSettings";

const BASE_URL_CUSTOMER = "/api/v1/customer";


const BASE_URL_CUSTOMER_v2 = "api/v1/private/customer/profile";
const BASE_URL_COUNTRIES = "/api/v1/active/countries";
const BASE_URL_LANGUAGES = "/api/v1/store/languages";
const BASE_URL_ORDERS = "/api/v1/private/orders"

class Customer extends Component {

  constructor(props) {
    super(props);

    let customerId = null;
    try {
      customerId = Number(props.match.params.id);

    } catch (error) {
      console.log("Error on extracting id from props!", error);
    }

    this.state = {
      customerId,
      customer: {},
      countries: [],
      languages: [],
      isEditNotesViewModalVisible: false,
      isManageAddressViewModalVisible:false,
      isBillingAddressViewModalVisible:false,
      isShippingAddressViewModalVisible:false,

    };
  }


  async componentDidMount() {
    await Promise.all([this.fetchCountries(), this.fetchLanguages()]);
    this.fetchCustomerDetails();
  }

  fetchCountries = async () => {
    try {
      this.showLoading();
      const { data: countries = [] } = await API.get(BASE_URL_COUNTRIES);
      this.setState({ countries });
    } catch (error) {
      console.log("fetchCountries:: Error on fetching countries!", error);
      this.showErrorNotification(
        "Error on fetching countries!" + error.message ?? ""
      );
    } finally {
      this.hideLoading();
    }
  };

  fetchLanguages = async () => {
    try {
      this.showLoading();
      const { data: languages = [] } = await API.get(BASE_URL_LANGUAGES);
      this.setState({ languages });
    } catch (error) {
      console.log("fetchLanguages:: Error on fetching languages!", error);
      this.showErrorNotification(
        "Error on fetching languages!" + error.message ?? ""
      );
    } finally {
      this.hideLoading();
    }
  };

  // fetchCustomerDetails = async () => {
  //   const { customerId } = this.state;
  //   if (!customerId) {
   
  //     return;
  //   }

  //   const url = `${BASE_URL_CUSTOMER}/${customerId}`;
  //   try {
  //     this.showLoading();
  //     const { data: customer } = await API.get(url);
  //     this.setState({ customer: customer ?? {} });
  //   } catch (error) {
  //     console.log(
  //       "fetchCustomerDetails:: Error on fetching customer details!",
  //       error
  //     );
  //     this.showErrorNotification(
  //       "Error on fetching customer details!" + error.message ?? ""
  //     );
  //   } finally {
  //     this.hideLoading();
  //   }
  // };


  fetchCustomerDetails = async ()=>{
  
    const { customerId } = this.state;
    if (!customerId) {
        return;
     }
     try{
      this.showLoading();
      const data = await API.get(`${BASE_URL_CUSTOMER_v2}/${customerId}`)
      if(data.status == 200){
        this.hideLoading();
        this.setState({ customer: data.data });
        console.log("customerDetails",data.data);
      }
     
     }
     catch(error){
      this.hideLoading()
      console.log("Error fetching customer details",error)
     }
  }

  findLanguageByCode = (code) => {
    const languages = this.state.languages ?? [];
    return languages.find((it) => it.code === code);
  };

  handleSaveNotes = (updatedNotes) => {
    const customer = { ...this.state.customer };
    customer.notes = updatedNotes;
    this.updateCustomerDetails(customer, () =>
      this.handleHideEditNotesViewModal()
    );
  };

  handleSaveContactInfo = (updatedCustomer) => {
    this.updateCustomerDetails(updatedCustomer, () =>
      this.handleHideEditContactInfoViewModal()
    );
  };

  handleSaveAddress = ( address,billAddress) => {
    
    this.saveCustomerAddress( address, billAddress,() =>
      this.handleHideManageAddressViewModal()
    );
  };
  handleEditBillingAddress = (billingAddress)=>{
    this.editBillingAddress(billingAddress, ()=>{
     this.handleHideBillingAddressViewModal()
    })
  }
  handleEditShippingAddress = (shippingAddress)=>{
    this.editShippingAddress(shippingAddress, ()=>{
     this.handleHideShippingAddressViewModal()
    })
  }
  showEditNotesViewModal = () => {
    const { customer } = this.state;
    this.setState({
      isEditNotesViewModalVisible: true,
      notesForModal: customer.notes ?? null,
    });
  };

  

  showEditContactInfoViewModal = () => {
    this.setState({ isEditContactInfoViewModal: true });
  };

  showBillingAddressViewModal = ()=>{
    this.setState({isBillingAddressViewModalVisible:true})
  }
  showShippingAddressViewModal = ()=>{
    this.setState({isShippingAddressViewModalVisible:true})
  }
 

  showManageAddressViewModal = (addressType = "shipping") => {
    this.setState({
      isManageAddressViewModalVisible: true,
      addressTypeForModal: addressType,
    });
  };

  handleHideEditNotesViewModal = () =>
    this.setState({
      isEditNotesViewModalVisible: false,
      notesForModal: null,
    });
  handleHideEditContactInfoViewModal = () =>
  this.setState({ isEditContactInfoViewModal: false });

  handleHideBillingAddressViewModal = ()=>{
    this.setState({isBillingAddressViewModalVisible:false})
  }

  handleHideShippingAddressViewModal = ()=>{
    this.setState({isShippingAddressViewModalVisible:false})
  }
  handleHideManageAddressViewModal = () => {
    this.setState({
      isManageAddressViewModalVisible: false,
      addressTypeForModal: null,
    });
  };
  // showMarketingSettingsViewModal = () => {
  //   this.setState({ isEditMrketingSettingViewModal: true })
  // }

  updateCustomerDetails = async (customer, onSuccess = (f) => f) => {
  
    try {
      this.showLoading();
      await API.put(`${BASE_URL_CUSTOMER}/${customer.id}`, customer);
      onSuccess();
      this.showSuccessNotification("Customer details updated.");
      this.fetchCustomerDetails();
    } catch (error) {
      console.log("updateCustomerDetails:: Error on updating customer!", error);
      this.showErrorNotification(
        "Error on updating customer details!" + error.message ?? ""
      );
    } finally {
      this.hideLoading();
    }
  };

  saveCustomerAddress = async ( address,billAddress, onSuccess = (f) => f) => {
    const { customer } = this.state;
    try {
      this.showLoading();
      let url = null;
      let payload = {};
       url = "/module/address";
      payload = { billingAddress: {...billAddress, billingAddress:true, isActive:true, isDefaultAddress:true},
                shippingAddress: {...address, isActive:true, isDefaultAddress:true, shippingAddress:true }};
      if (address.id) url += `/${address.id}`;
      else url += `?user=${customer.id}`;
      if (address.id) await API.patch(url, address);
      else await API.post(url, payload);
      onSuccess();
      this.showSuccessNotification("Address saved successfully!");
      this.fetchCustomerDetails()
    } catch (error) {
      console.log("updateCustomerDetails:: Error on saving address!", error);
      this.showErrorNotification(
        "Error on saving address!" + error.message ?? ""
      );
    } finally {
      this.hideLoading();
    }
  };

  editBillingAddress = async(billingAddress,onSuccess = (f) => f)=>{
    const { customer } = this.state;
    try {
    let billingUrl = null;
    billingUrl= "/module/address/billing";
    if(billingAddress.id) billingUrl+= `/${billingAddress.id}`;
    await API.patch(billingUrl,billingAddress)
    onSuccess();
    this.showSuccessNotification("Address saved successfully!");
    this.fetchCustomerDetails()
    }
    catch (error) {
      console.log("updateCustomerDetails:: Error on saving address!", error);
      this.showErrorNotification(
        "Error on saving address!" + error.message ?? ""
      );
    } finally {
      this.hideLoading();
    }
  }
  editShippingAddress = async(shippingAddress,onSuccess = (f) => f)=>{
    const { customer } = this.state;
    try {
    let shippingUrl = null;
    shippingUrl= "/module/address/shipping";
    if(shippingAddress.id) shippingUrl+= `/${shippingAddress.id}`;
    await API.patch(shippingUrl,shippingAddress)
    onSuccess();
    this.showSuccessNotification("Address saved successfully!");
    this.fetchCustomerDetails()
    }
    catch (error) {
      console.log("updateCustomerDetails:: Error on saving address!", error);
      this.showErrorNotification(
        "Error on saving address!" + error.message ?? ""
      );
    } finally {
      this.hideLoading();
    }
  }

 

  getDefaultAddress = (customer) => {
    const { newShippingAddress } = customer ?? {};
    if (!newShippingAddress || !newShippingAddress.length) {
  
      return "";
    }
   
    const defaultAddress = newShippingAddress.find(
      (address) => address.isDefaultAddress ?? false
    );
    if (!defaultAddress) {
   
      return "";
    }
    console.log("defaultAddress",defaultAddress)
    const { firstName, lastName, country ,address,apartment,city,postalCode,mobNumber,stateProvince } = defaultAddress;
    const Name  = `${firstName}${lastName}`
    const countryName = this.findCountryByCode(country)?.name ?? null;
    const addressItems = [Name, address,apartment,city,postalCode,stateProvince,mobNumber,countryName];
    return addressItems.filter((it) => it).join("\r\n");
  };

  findCountryByCode = (code) => {
    const { countries } = this.state;
    return countries === null || !countries.length
      ? null
      : countries.find((country) => country.code === code);
  };

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });
    createOrder = () => {
      const { customerId } = this.state;
      if (customerId) {
        this.props.history.push(`/dashboard/orders/new`);
      }
    };


  renderLastOrderPlacedCard = () => {
    const { customer } = this.state;
    console.log("customer: ",customer)
    const lastOrder = customer.pastOrders && customer.pastOrders[0];
  
       const customerId = customer.id
    const lastOrderId = lastOrder?.orderId || "";
    const paymentType = lastOrder?.paymentType;
    const status = lastOrder?.status;
    const total = lastOrder?.total;
    const currency = lastOrder?.currency
    const datePurchased = lastOrder?.datePurchased;
    const orderProducts = lastOrder?.orderProducts || [];

    const viewOrders = () => {
      window.location.href = "";
    };

    return (
      <Card style={{ background: "#FFFFFF" }}>
        <Card.Body>
          <div className="row">
            <div className="col-12">
              <p className="h6">Last Order Placed</p>
              <div className="d-flex align-items-start">
                <a href={`/admin/dashboard/orders/${lastOrderId}`}>{`#${lastOrderId}`}</a>
                <span className="px-2">{paymentType}</span>
                <span className="px-1">{status}</span>
                <div className="flex-grow-1 text-end">
                  <span className="px-1">{currency}</span>
                  <span className="px-1">{total}</span>
                </div>
              </div>
              <div>{`${formatDateTime(datePurchased)}`}</div>
              {orderProducts.map((product, index) => (
                <div className="d-flex align-items-start mt-3" key={index}>
                  <div className="position-relative me-2">
                    <img
                      src={product.productImage}
                      height="50px"
                      width="60px"
                      alt="Cart Item Pic"
                      className="item img-thumbnail"
                    />
                    <div
                      
                      style={{
                        zIndex: "100", background: "#DDE0E4", color: "black",
                        position: "absolute",
                        top: "-5px",
                        right: "-10px",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px"
                      }}
                    >
                      {product.productQuantity}
                    </div>
                  </div>

                  <div className="flex-grow-3">{product.productName}</div>
                  <div className="flex-grow-1 text-end">
                    {/* {product.prices.map((price, priceIndex) => ( */}
                      <div key={index}>
                        <span className="px-1">{currency}</span>
                        <span className="px-2">{product.productPrice}</span>
                      </div>
                    {/* ))} */}
                  </div>
                </div>
              ))}
              <div className="d-flex justify-content-end mt-3">
               
              </div>
            </div>
          </div>
        </Card.Body>
        <Card.Footer style={{ background: "#FFFFFF" }}>
          <button style={{background:"#247A81",borderRadius:"20px"}} onClick={this.createOrder}
             type="button" className="btn btn-md btn-success my-2 float-end">
            Create Order
          </button>
          <button style={{background:"#247A81",borderRadius:"20px"}} onClick={viewOrders} type="button" className="btn btn-md btn-success mx-2 my-2 float-end">
            View All Orders
          </button>
        </Card.Footer>
      </Card>
    );
  };






  renderNotesCard = () => {
    const { customer } = this.state;
    return (
      <NotesCard
        notes={customer?.notes ?? ""}
        onEdit={this.showEditNotesViewModal}
      />
    );
  };

  renderCustomerDetailsCard = () => {
    const { customer } = this.state;
    const language = customer
      ? titleCase(this.findLanguageByCode(customer.language)?.name ?? "")
      : "";
    const contactInfo = (
      <div className="row mt-2">
        <span className="text fw-semibold mb-2">Contact information</span>
        <span><a href={`mailto:${customer?.emailAddress ?? ""}`}>
          {customer?.emailAddress ?? ""}
        </a></span>
        <span>
          {language ? `Will receive notifications in ${language}` : ""}
        </span>
      </div>
    );

    const defaultAddressInfo = (
      <div className="row mt-2">
        <span className="text fw-semibold mb-2">Default address</span>
        <span style={{ whiteSpace: "pre-wrap" }}>
          {this.getDefaultAddress(customer)}
        </span>
      </div>
    );

   const isEmailSubscribed = customer.emailSubscription;
    const marketingInfo = (
  
      <div className="row mt-2">
        <span className="text fw-semibold mb-2">Marketing</span>
        
        <span>
      <span className={`dot${isEmailSubscribed ? '-filled' : ''}`}></span>
      {isEmailSubscribed ? 'Email subscribed' : 'Email not subscribed'}
    </span>
        {/* <span>
          <span className="dot"></span>SMS not subscribed
        </span> */}
      </div>
    );

    const manageDropdown = (
      <Dropdown>
        <Dropdown.Toggle variant="link" id="manage-dropdown">
          Manage
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            href="#"
            onClick={() => this.showEditContactInfoViewModal()}
          >
            Edit contact information
          </Dropdown.Item>
          <Dropdown.Item
            href="#"
            onClick={() => this.showBillingAddressViewModal()}
          >
            Edit billing addresses
          </Dropdown.Item>
          <Dropdown.Item
            href="#"
            onClick={() => this.showShippingAddressViewModal()}
          >
            Edit shipping addresses
          </Dropdown.Item>
          <Dropdown.Item
            href="#"
            onClick={() => this.showManageAddressViewModal()}
          >
           Add addresses
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );

    return (
      <Card style={{ background: "#FFFFFF" }}>
        <Card.Body>
          <div className="row">
            <div className="col">
              <p className="h6 fw-semibold">Customer</p>
            </div>
            <div className="col text-end mt-0">{manageDropdown}</div>
          </div>
          {contactInfo}
          {defaultAddressInfo}
          {marketingInfo}
        </Card.Body>
      </Card>
    );
  };

  render() {
    const {
      customer,
      countries,
      languages,
      isEditNotesViewModalVisible,
      isEditContactInfoViewModal,
      isManageAddressViewModalVisible,
      isBillingAddressViewModalVisible,
      isShippingAddressViewModalVisible
    } = this.state;

    const cardLastOrderPlaced = this.renderLastOrderPlacedCard();
    const notesCard = this.renderNotesCard();
    const customerDetailsCard = this.renderCustomerDetailsCard();
    const customerCountry = this.findCountryByCode(customer?.delivery?.country);

    return (
      <div className="container">
        <div class="d-flex justify-content-start-0">
          <div style={{ alignSelf: "flex-start", marginRight: "1rem" }}>
            <Button
              onClick={() => this.props.history.goBack()}
              
              color="inherit"
              size="large"
            >
              <ArrowBackIcon />
            </Button>
          </div>
          <div className="d-flex flex-column  mt-1">

            <h4>{customer?.emailAddress ?? ""}</h4>
            <span className="text-muted">{customerCountry?.name ?? ""}</span>
          </div>
        </div>
        <div class="d-flex justify-content-between mt-4">
          <div className="col-8" style={{ marginRight: "16px" }}>
            {cardLastOrderPlaced}
          </div>
          <div className="col-4">
            <div className="d-flex flex-column">
              {notesCard}
              {customerDetailsCard}
            </div>
          </div>
        </div>
        <EditNotesViewModal
          notes={this.state.notesForModal ?? ""}
          title="Edit notes"
          onHide={this.handleHideEditNotesViewModal}
          onSave={this.handleSaveNotes}
          isVisible={isEditNotesViewModalVisible ?? false}
        />
        <EditContactInfoViewModal
          title="Edit customer"
          contactInfo={customer}
          onHide={this.handleHideEditContactInfoViewModal}
          onSave={this.handleSaveContactInfo}
          isVisible={isEditContactInfoViewModal ?? false}
        />
        <EditBillingAddressModel
        title = "Billing Address"
        onHide={this.handleHideBillingAddressViewModal}
        onSave={this.handleEditBillingAddress}
        addresses = {
          customer.newBillingAddress ?? []
        }
        countries={countries}
        isVisible={isBillingAddressViewModalVisible ?? false}
        />
        <EditShippingAddressModel
        title = "Shipping Address"
        onHide={this.handleHideShippingAddressViewModal}
        onSave={this.handleEditShippingAddress}
        addresses = {
          customer.newShippingAddress ?? []
        }
        countries={countries}
        isVisible={isShippingAddressViewModalVisible ?? false}/>
        <ManageAddressViewModal
          countries={countries}
          onHide={this.handleHideManageAddressViewModal}
          onSave={this.handleSaveAddress}
          isVisible={isManageAddressViewModalVisible ?? false}
        /> 



      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

// export default connect(mapStateToProps, mapDispatchToProps)(Customer);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Customer))
