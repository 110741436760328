import React, { useState,Component, useEffect } from "react";
import { connect } from "react-redux";
import { Card ,Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import LinkActionButton from "../../components/LinkActionButton";
import { Link } from "react-router-dom";
import TableConfig from "../../utils/tableConfig";
import API from "../../utils/API";
import moment from "moment";
import Search from "../../components/Search/Search";
import { formatDate, formatDateTime } from "../../utils/common";
import { CheckBox } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import DeliveryStatusPopUp from "./DeliveryStatusPopUp"
import BulkCancelOrderPopup from "./BulkCancelOrderPopup";

// import ArrowImage from "../../assets/img/arrow.svg"
import CrossIcon from "../../assets/img/cross_icon.png"

const BASE_URL_ORDERS = "/api/v1/search/paginated/orders";

const BASE_URL_COUNTRIES = "/api/v1/active/countries";

const PopUpForFilters = ({ isVisible=false, onClearFilter,onHide, applyFilter ,selectFilterMain}) => {
  const history  = useHistory()
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [activeCountries, setActiveCountries] = useState([]);
  // const [searchParams, setSearchParams] = useSearchParams();


  const fetchCountries = async () => {
    const data = await API.get(BASE_URL_COUNTRIES);
    if (data.status === 200) {
      setActiveCountries(data.data);
    }
    console.log('activeCountries', data);
  };
  const handleFilterChange = (filter) => {
  
    setSelectedFilters((prevFilters) =>
      prevFilters.includes(filter)
        ? prevFilters.filter((item) => item !== filter)
        : [...prevFilters, filter]
    );
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if(selectFilterMain!== undefined &&  selectFilterMain!== null){
    const transformedFilters = Object.keys(selectFilterMain).reduce((acc, key) => {
      return [...acc, ...selectFilterMain[key]];
    }, []);
    
    setSelectedFilters(transformedFilters)}
    ;
  }, [selectFilterMain]);

  const handleClearFilters = () => {
    setSelectedFilters([]);
    setTimeout(() => {
      onClearFilter(selectedFilters);
    }, 1000);
  };
   

  const getPayloadFromFilters = (selectedFilters) => {
    const payload = {
      countries: selectedFilters.filter((filter) => activeCountries.map((country) => country.shortName).includes(filter)),
      paymentStatus: selectedFilters.filter((filter) => ['PAID', 'UNPAID', 'Refunded'].includes(filter)),
      orderStatus: selectedFilters.filter((filter) => ['CREATED', 'PROCESSING', 'SHIPPED', 'CANCELED', 'DELIVERED'].includes(filter)),
    };
    console.log("payload",payload)
    return payload;
  };

  return (
    <Modal show={isVisible} onHide={(selectFilterMain)=>onHide(selectFilterMain)} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Select Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="filter-div" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {/* Payment Filter */}
          <div className="payment-filter">
            <label>
              <b>Payment</b>
            </label>
            <div className="filter-option">
              <div className="input-div">
              <input className="checkbox"
                type="checkbox"
                onChange={() => handleFilterChange('PAID')}
                checked={selectedFilters?.includes('PAID')}
              />
              Paid
              </div>
              <div className="input-div">
              <input className="checkbox"
                type="checkbox"
                style={{ marginLeft: '30px' }}
                onChange={() => handleFilterChange('UNPAID')}
                checked={selectedFilters.includes('UNPAID')}
              />
              Unpaid
              </div>
              {/* <div className="input-div">
              <input className="checkbox"
                type="checkbox"
                style={{ marginLeft: '30px' }}
                onChange={() => handleFilterChange('Refunded')}
                checked={selectedFilters.includes('Refunded')}
              />
              Refunded
              </div> */}
            </div>
          </div>

          <div className="country-filter">
            <label>
              <b>Country</b>
            </label>
            <div className="filter-option" style={{ display: 'flex', gap: '40px' }}>
              {activeCountries.map((data, index) => (
                <div className="input-div" key={index}>
                  <input className="checkbox"
                    type="checkbox"
                    onChange={() => handleFilterChange(data.shortName)}
                    checked={selectedFilters.includes(data.shortName)}
                  />
                  {data.code}
                </div>
              ))}
            </div>
          </div>

          {/* Order Filter */}
          <div className="order-filter">
            <label>
              <b>Order</b>
            </label>
            <div className="filter-option">
              <div className="input-div">
              <input className="checkbox"
                type="checkbox"
                onChange={() => handleFilterChange('CREATED')}
                checked={selectedFilters.includes('CREATED')}
              />
              Created
              </div>
              <div className="input-div">
              <input className="checkbox"
                type="checkbox"
                style={{ marginLeft: '30px' }}
                onChange={() => handleFilterChange('PROCESSING')}
                checked={selectedFilters.includes('PROCESSING')}
              />
              PROCESSING
              </div>
              <div className="input-div">
              <input className="checkbox"
                type="checkbox"
                style={{ marginLeft: '30px' }}
                onChange={() => handleFilterChange('SHIPPED')}
                checked={selectedFilters.includes('SHIPPED')}
              />
              SHIPPED
              </div>
              <div className="input-div">
              <input className="checkbox"
                type="checkbox"
                style={{ marginLeft: '30px' }}
                onChange={() => handleFilterChange('CANCELED')}
                checked={selectedFilters.includes('CANCELED')}
              />
              Cancelled
              </div>
              <div className="input-div">
              <input className="checkbox"
                type="checkbox"
                style={{ marginLeft: '30px' }}
                onChange={() => handleFilterChange('DELIVERED')}
                checked={selectedFilters.includes('DELIVERED')}
              />
              Delivered
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          style={{ borderRadius: '20px' }}
          type="button"
          className="btn btn-md btn-outline-secondary"
          onClick={handleClearFilters}
        >
          Clear filters
        </button>
        <button
          style={{ background: '#247A81', borderRadius: '20px' }}
          type="button"
          className="btn btn-md btn-success ml-2"
          onClick={(event) => applyFilter(getPayloadFromFilters(selectedFilters))}
        >
          Apply Filter
        </button>
      </Modal.Footer>
    </Modal>
  );
};



class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      sortField: "order_id",
      sortDirection: "desc",
      number:0,
      recordsTotal:0,
      recordsFiltered:15,
      totalPages:0,
      searchValue:"",
      clearSearch:"false",
      isPopUpForFiltersVisible:false,
      startDate:"",
      endDate:"",
      selectedFiltersMain:[],
      isPopUpVisibleForDeliveryStatus:false,
      isPopUpVisibleForBulkCancel:false,
      selectedRows:[],
      selectedRowsOrderStatus:{}
      // isCheckedState:false,
      
      
    };
    
  }

  componentDidMount() {
    this.fetchOrders();
  }

  
  // handleHeaderCheckboxChange = (e)=>{
  //   const {orders,} = this.state;
  //   const allOrders = orders.filter((row)=>
  //     row.orderStatus!=="DELIVERED" && row.orderStatus!=="CREATED" &&
  //    row.orderStatus!== "CANCELED").map((row)=> row.orderId);
  //   this.setState({selectedRows:e.target.checked ? allOrders : []})
  // }

  handleHeaderCheckboxChange = (e) => {
    const { orders } = this.state;
  
    // Filter out orders with specific statuses and map to order IDs
    const allOrders = orders
      .filter((row) => row.orderStatus !== "CANCELED" &&  row.orderStatus!=="DELIVERED")
      .map((row) => row.orderId);
  
    // If the header checkbox is checked, select all order IDs and their statuses
    if (e.target.checked) {
      const selectedRowsOrderStatus = {};
  
      orders.forEach((row) => {
        if (row.orderStatus !== "CANCELED" && row.orderStatus !== "DELIVERED") {
          selectedRowsOrderStatus[row.orderId] = row.orderStatus;
        }
      });
  
      // Update both selectedRows and selectedRowsOrderStatus
      this.setState({
        selectedRows: allOrders,
        selectedRowsOrderStatus: selectedRowsOrderStatus
      });
    } else {
      // If unchecked, clear selectedRows and selectedRowsOrderStatus
      this.setState({
        selectedRows: [],
        selectedRowsOrderStatus: {}
      });
    }
  };
  

  handleCheckBoxChange = (e, row) => {
    const { selectedRows,selectedRowsOrderStatus,orders } = this.state;

    const rowOrderStatus = orders.find((data) => data.orderId === row.orderId)?.orderStatus;
    const updatedSelectedRows = e.target.checked ? [...selectedRows, row.orderId] : selectedRows.filter(orderId => orderId !== row.orderId);

    const updatedSelectedRowsOrderStatus = { ...selectedRowsOrderStatus };

    // Update the order status respective to the order ID
    if (e.target.checked) {
      updatedSelectedRowsOrderStatus[row.orderId] = rowOrderStatus;
    } else {
      delete updatedSelectedRowsOrderStatus[row.orderId];
    }
    // const updatedSelectedRowsOrderStatus = e.target.checked ? [...selectedRowsOrderStatus,orderStatus] : selectedRowsOrderStatus.filter(orderStatus => orderStatus !== orderStatus);
    this.setState({ selectedRows: updatedSelectedRows,selectedRowsOrderStatus: updatedSelectedRowsOrderStatus});
}



putCheckBox = (row) => {
  const { selectedRows, orders,selectedRowsOrderStatus } = this.state || [];
  const orderStatus = orders.find((data) => data.orderId === row.orderId)?.orderStatus;
   const isChecked = selectedRows.includes(row.orderId);
  // this.setState({selectedRowsOrderStatus:orderStatus})
  return (
      <>
          
          {
          orderStatus === "DELIVERED" || 
          // orderStatus=== "CREATED" || 
          orderStatus=== "CANCELED" ? (
              <div></div>
          ) : (
              <input
                  type="checkbox"
                  value={row.orderId}
                  checked={isChecked}
                  onChange={(e) => this.handleCheckBoxChange(e, row)}
              />
           )}
      </>
  );
}



  getFormattedPurchasedDate = (purchasedDate) => {
    const date = moment(purchasedDate).format("MMM DD, YYYY");
    const time = moment(purchasedDate).format("hh:mm A");
    return `${date} at ${time}`;
  };

  rendertransactionReference = (transactionReference) =>{
    return (
      <div style={{
        maxHeight: "60px",
        overflowY: "auto",
        display: "-webkit-box",
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
        textOverflow: "ellipsis",
      }}>
        {transactionReference}
      </div>
    )
  }
  getOrderStatusCellStyle = (order) => {
    if (order.orderStatus === 'CREATED') {
      return {
        padding:"10px",
        color: 'purple', 
        fontWeight:"700"
       
      };
    } else if (order.orderStatus === 'PROCESSING') {
      return {
        padding:"10px",
        color: '#EEB00C', 
        fontWeight:"700"
      };
    }
     else if (order.orderStatus === 'SHIPPED') {
      return {
        padding:"10px",
        color: '#35C359', 
        fontWeight:"700"
      };
    } else if (order.orderStatus === 'CANCELED') {
      return {
        padding:"10px",
        color: '#FF4F50', 
        fontWeight:"700"
      };
    } else if (order.orderStatus === 'DELIVERED') {
      return {
        padding:"10px",
        color: '#0091A6', 
        fontWeight:"700"
      };
    }
    else if(order.orderStatus === "PARTIALLY_CANCELED"){
      return{
        padding:"10px",
        color: '#EA1EA5', 
        fontWeight:"700"
      }
    }
    return {};
  };
  

  fetchOrders = async (selectedFilters) => {

    const payloadData = {
      countries: selectedFilters?.countries?.join(','), 
      paymentStatus: selectedFilters?.paymentStatus?.join(','), 
      orderStatus: selectedFilters?.orderStatus?.join(','), 
      };
    
    const payload = {
      ...payloadData,
      startDate: this.state.startDate ?? null ,
      endDate: this.state.endDate ?? null,
    }

    
    try {
      this.showLoading();
      const { data } = await API.get(BASE_URL_ORDERS, {
        params:{
          key:this.state.searchValue,
          search_by:"",
          page:this.state.number,
          sort_order:this.state.sortDirection,
          sort_by:this.state.sortField,
          count:this.state.recordsFiltered ,
          // ...payload
          countries: selectedFilters?.countries?.join(',') ?? null, 
          paymentStatus: selectedFilters?.paymentStatus?.join(',') ?? null, 
          orderStatus: selectedFilters?.orderStatus?.join(',') ?? null, 
          startDate: this.state.startDate ?? null ,
      endDate: this.state.endDate ?? null,
         

        }
      });

      const { orders = [], recordsTotal,totalPages,number  } = data;
      console.log("data",data)
      this.setState({ orders,recordsTotal,totalPages });

    } catch (error) {
      console.log("fetchOrders:: Error on fetching customers!", error);
      this.showErrorNotification("Error on fetching orders!" + error.message ?? "");
    } finally {
      this.hideLoading();
    }
  };

  handleSort = (column, sortDirection) => {
    this.setState(
      {
        sortField: column.sortField ,
        sortDirection: sortDirection,
      },()=> this.fetchOrders(this.state.selectedFiltersMain)
    );
  };

  handlePageChange = (number) => {
   
    this.setState({number:number-1},()=>{
      this.fetchOrders(this.state.selectedFiltersMain);
    })
    
  };

  handlerecordsFilteredChange = (recordsFiltered) => {
    console.log("recordsFiltered",recordsFiltered)
    this.setState({recordsFiltered:recordsFiltered},()=> this.fetchOrders(this.state.selectedFiltersMain))
   
  };
  handleSearch = (val)=>{
    this.setState({ searchValue: val });
    if(!val){
      this.fetchOrders(this.state.selectedFiltersMain);
    }
    this.setState({selectedRows:[]})
  }
  clearSearch = ()=>{
    this.setState({ searchValue: "",clearSearch:true },()=>{
      this.fetchOrders(this.state.selectedFiltersMain);
    })
    this.setState({selectedRows:[]})
  }

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

    onSearchBtn=()=>{
      this.fetchOrders(this.state.selectedFiltersMain);
    }
     handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        this.fetchOrders(this.state.selectedFiltersMain);
      }
  }

  handleShowPopUp = ()=>{
    this.setState({isPopUpForFiltersVisible:true})
  }
  updateUrlWithFilters = (filters) => {
    const queryParams = new URLSearchParams();
  
    for (const key in filters) {
      if (Array.isArray(filters[key]) && filters[key].length > 0) {
        queryParams.set(key, filters[key].join(','));
      }
    }
  
    // Update the URL with the new query parameters
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  };

 handleHidePopUpForFilters = (selectedFilters) => {
  this.setState({ selectedFiltersMain: selectedFilters }, () => {
    this.fetchOrders(this.state.selectedFiltersMain);
    this.updateUrlWithFilters(selectedFilters);
  });
  this.setState({ isPopUpForFiltersVisible: false });
};

handleClosePopUp = (selectedFilters)=>{
  this.setState({selectFilterMain:selectedFilters})
    this.setState({isPopUpForFiltersVisible:false})
  
}
handleHidePopupForFiltersVisible=()=>{}
  handleApplyFilters = (selectFilter)=>{
    const {selectedRows} = this.state

    const queryParams = new URLSearchParams();
    for (const key in selectFilter) {
      if (Array.isArray(selectFilter[key]) && selectFilter[key].length > 0) {
        queryParams.append(key, selectFilter[key].join(','));
      }
    }
  
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);

    
    this.setState({ selectedFiltersMain: selectFilter }, () => {
      this.fetchOrders(this.state.selectedFiltersMain);
    });

   
    this.handleHidePopUpForFilters(selectFilter);

    this.setState({selectedRows:[]})
  }

   getQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const queryParams = {};

    for (const [key, value] of urlParams.entries()) {
      
      queryParams[key] = value.split(',');
    }

    return queryParams;
  };

  componentDidMount() {
    // Function to get query parameters from the URL
    


    const queryParams = this.getQueryParams();

    this.setState({ selectedFiltersMain: queryParams }, () => {
      this.fetchOrders(this.state.selectedFiltersMain)})

   
  }

  handleRemoveFilter = (filterType, filterValue) => {
    // Create a copy of the selectedFilters state
    const updatedFilters = { ...this.state.selectedFiltersMain };
  
    // Remove the specified filter and value
    if (updatedFilters[filterType]) {
      updatedFilters[filterType] = updatedFilters[filterType].filter(item => item !== filterValue);
  
      // If the filter type is now empty, remove it from selectedFilters
      if (updatedFilters[filterType].length === 0) {
        delete updatedFilters[filterType];
      }
  
      // Set the updatedFilters state
      this.setState({ selectedFiltersMain: updatedFilters }, () => {
        // Call fetchOrders to update data based on modified filters
        this.fetchOrders(this.state.selectedFiltersMain);
        this.updateUrlWithFilters(updatedFilters);
      });
    }
  };

  handleDeliverStatus = ()=>{
    this.setState({isPopUpVisibleForDeliveryStatus:true})
  }
  handleCancelOrder = ()=>{
    this.setState({isPopUpVisibleForBulkCancel:true})
  }

  handleHideDeliveryStatusPopUp = ()=>{
    this.setState({isPopUpVisibleForDeliveryStatus:false})
  }
  handleHideBulkCancelPopUp = ()=>{
    this.setState({isPopUpVisibleForBulkCancel:false})
  }

  handleDeliveryStatusChange = async()=>{
    const {selectedRows} = this.state;
    const admin_user_id = localStorage.getItem("adminUserId") || ""
    this.handleHideDeliveryStatusPopUp();
    try{
      this.showLoading()
     const res= await API.patch(`/api/v1/delivery/status?admin_user_id=${admin_user_id}&orderIds=${selectedRows}`)
      if(res.status === 200){
        this.hideLoading();
        this.showSuccessNotification("Order Status Updated SuccessFully");
        window.location.reload();
      }
      else{
        this.hideLoading();
        console.log("error in order delivered status")
      }
    }
    catch(error){
      this.hideLoading();
      console.log(error)
    }
  }

  handleBulkCancelChange = async()=>{
    const {selectedRows} = this.state;
    const admin_user_id = localStorage.getItem("adminUserId") || ""
    this.handleHideBulkCancelPopUp();
    try{
      const payload = {
        "cancelReason": "",
        "orderIdList": selectedRows 
      }
      this.showLoading()
      const timeoutConfig = { timeout: 600000 };
     const res= await API.put(`/api/v1/cancel/orders?admin_user_id=${admin_user_id}`,payload,timeoutConfig)
      if(res.status === 200){
        this.hideLoading();
        this.showSuccessNotification("Order Status Updated SuccessFully");
        window.location.reload();
      }
      else{
        this.hideLoading();
        console.log("error in order delivered status")
      }
    }
    catch(error){
      this.hideLoading();
      console.log(error)
    }
  }
 

  



  renderOrderTotal = (order) => {
    if (!order) return "";
    const { currency, finalAmount } = order;
    return `${currency ?? ""} ${finalAmount ?? ""}`.trim();
  };
  renderPaymentStatus = (order) => {
    if(!order) return "0";
    const {payment} = order;
    return payment?.paymentType;
  }
  // renderCustomerDetails = (order) => {
  //   if (!order) return "";
  //   const address = order.delivery ?? order.billing ?? {};
  //   const { firstName, lastName } = address;
  //   return `${firstName ?? ""} ${lastName ?? ""}`.trim();
  // };

  renderItems = (order) => {
    if (!order || !order.itemCount) return "";

    const { itemCount } = order;
    return `${itemCount} ${itemCount > 1 ? "items" : "item"}`;
  }
  render() {

    const columns = [
      {
        name:(
          <input
          type="checkbox"
          checked={this.state.selectedRows.length === this.state.orders.filter((row)=>
            row.orderStatus!=="DELIVERED" &&
            //  row.orderStatus!=="CREATED" && 
            row.orderStatus!== "CANCELED").map((row)=> row.orderId).length
        && this.state.selectedRows.length > 0}
          onChange={this.handleHeaderCheckboxChange}
          />
        ),
        selector:"checkbox",
        cell:(row)=>this.putCheckBox(row)
      },
      {
        name: "Order ID",
        sortField: "order_id",
        selector: (row) => row.orderId,
        wrap: false,
        sortable: true,
        minWidth: "150px",
      },
      {
        name: "Date",
        selector: (row) => row.dateCreated,
        sortField: "date",
        wrap: true,
        sortable: true,
        minWidth: "150px",
        format: (order) =>
          order.dateCreated
            ? this.getFormattedPurchasedDate(order.dateCreated)
            : "-",
      },
      {
        name: "Customer",
        selector: (order) => order.customerFullName,
        sortField: "",
        wrap: true,
        // sortable: true,
        minWidth: "150px",
        // format: (order) => this.renderCustomerDetails(order),
      },
      {
       name:"Email",
       selector:(order)=>order.customerEmail,
       wrap:true,
       sortField: "customer_email",
       sortable:true,
      //  center:true,
       minWidth: "150px",

      }
      ,
      {
        name:"Country",
        selector: (order) =>  order.country ,
        wrap:true,
        minWidth:"100px",
        center:true
        // format:
      },
      {
        name: "Total",
        selector: (order) => order.orderTotal,
        sortField: "total",
        wrap: true,
        // sortable: true,
        center:true,
        // format: (order) => this.renderOrderTotal(order),
        minWidth:"100px",
      },
      {
        name:"Payment Status",
        selector: (order) => order.paymentStatus,
        wrap:true,
        center:true,
        sortField: "payment_status",
        minWidth:"150px",
        // sortable:true,
        // format: (order) => this.renderPaymentStatus(order)
      },
      // {
      //   name: "Payment status",
      //   selector: (order) => order.paymentStatus,
      //   wrap: true,
      //   minWidth: "200px",
      //   format: (order) => "Paid",
      // },
      {
        name: "Order Status",
        selector: (order) => (
          <div
            className="order-status-cell"
            style={this.getOrderStatusCellStyle(order)}
          >
            {order.orderStatus ?? ''}
          </div>
        ),
        wrap: true,
        center:true,
        // sortable:true,
        sortField:"fulfillment_status",
        minWidth: "200px",
        // format: (order) => order.orderStatus ?? "",
        // format:(order)=> this.showColorOrderStatus(order)
      },
      {
        name: "Verification Status",
        selector: (order) => order.verificationStatus,
        wrap: true,
        center:true,
        // sortable:true,
       
        minWidth: "170px",
        // cell:(order) => order.otpVerificationStatus== true ? "Verified" : "Not Verified"
      },
      {
        name: "Courier Partner",
        selector: (order) => order.courierPartner,
        wrap: true,
        center:true,
        // sortable:true,
       
        minWidth: "170px",
        
      },
      {
        name: "Items",
        selector: (order) => order.orderItemCount,
        wrap: true,
        // sortable: true,
        // format: (order) => this.renderItems(order),
      },
      {
        name: "Transaction NO.",
        sortField: "transactionReference",
        selector: (row) => row.transactionReference,
        wrap: false,
        // sortable: true,
        minWidth: "150px",
        cell:(row)=> this.rendertransactionReference(row.transactionReference)
      },
      {
        name: "Order No.",
        sortField: "orderNumber",
        selector: (row) => row.orderNumber,
        wrap: false,
        sortable: true,
        minWidth: "150px",
      },
    ];
    const { recordsTotal, totalPages,recordsFiltered,number ,isCancelOrderpopupVisible} = this.state;
    const orders = this.state.orders ?? [];
    const selectedRows = this.state.selectedRows
    const selectedRowsOrderStatus= this.state.selectedRowsOrderStatus
    console.log("selectedRowsOrderStatus",selectedRowsOrderStatus)
    return (
      <div className="manage-package">
        <div className="header-div" style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>

        <div className="filter-section">
          <div className="filter-box">
        <div className="date-div">
          <div className="start-date" >
            <h6>Start</h6>
            <input type="date" value={this.state.startDate} onChange={(e)=> this.setState({startDate:e.target.value,selectedRows:[]} ,
            ()=>this.fetchOrders(this.state.selectedFiltersMain))}
            />

          </div>
          <div className="end-date">
            <h6>End</h6>
            <input type="date" value={this.state.endDate} onChange={(e)=> this.setState({endDate:e.target.value,selectedRows:[]},
              ()=>this.fetchOrders(this.state.selectedFiltersMain))} />
          
          </div>
        </div>
          <div className="select-filter">
            <h6>Filter</h6>
            <div className="filter" onClick={this.handleShowPopUp} style={{marginLeft:"10px"}} >
              Select Filter  
            
            <img src="https://dzoweysldjhvw.cloudfront.net/79b9254cb9d2cc4c405a.svg" style={{marginLeft:"10px"}}></img>
            </div>
          </div>
          </div>
         
        </div>


       <div className="rightextreme" style={{marginBottom:20}}>
       
        <div className="action-container">
            <div className="search-div">
            <Search
                  searchExpanded={true}
                  searchTerm={this.state.searchValue}
                  handleSearch={this.handleSearch}
                  clearSearch={this.clearSearch}
                  handleSearchExit={()=>{}}
                  onKeyPress={this.handleKeyPress}
        ></Search>
            </div>
         
        <div className="search-btn" onClick={this.onSearchBtn}>
              Search
            </div>
            <div className="page-header">
            <div className="actions" style={{marginBottom:0}}>
            <div >
            <Link to={`/dashboard/orders/new/`}> <button className="greenbackground" >Create Order</button></Link>
          </div>
          </div>
          </div>
          </div>
         
        
        </div>
        
       
        </div>



        <div>
  {this.state.selectedFiltersMain ? (
    <div className="filter-result" >
      {this.state.selectedFiltersMain.paymentStatus && (
        <p>
           {this.state.selectedFiltersMain.paymentStatus.map(paymentStatus => (
            <div className="filter-list-item">
            <span key={paymentStatus}>
              {paymentStatus}
              <img
                src={CrossIcon}
                alt="Remove filter"
                onClick={() => this.handleRemoveFilter('paymentStatus', paymentStatus)}
                style={{ cursor: 'pointer', marginLeft: '5px' }}
              />
            </span>
            </div>
          ))}
        </p>
      )}
      {this.state.selectedFiltersMain.countries && (
        <p>
          {this.state.selectedFiltersMain.countries.map(country => (
            <div className="filter-list-item">
            <span key={country}>
              {country}
              <img
                src={CrossIcon}
                alt="Remove filter"
                onClick={() => this.handleRemoveFilter('countries', country)}
                style={{ cursor: 'pointer', marginLeft: '5px' }}
              />
            </span>
            </div>
          ))}
        </p>
      )}
      {this.state.selectedFiltersMain.orderStatus && (
        <p>
          {this.state.selectedFiltersMain.orderStatus.map(orderStatus => (
           <div className="filter-list-item">
           <span key={orderStatus}>
              {orderStatus}
              <img
                src={CrossIcon}
                alt="Remove filter"
                onClick={() => this.handleRemoveFilter('orderStatus', orderStatus)}
                style={{ cursor: 'pointer', marginLeft: '5px' }}
              />
            </span>
            </div>
          ))}
        </p>
      )}
    </div>
  ) : (
    ""
  )}
</div>


        <Card body >
          <DataTable
            data={orders}
            columns={columns}
            sortServer
            onSort={this.handleSort}
            defaultSortField="orderId"
            defaultSortAsc={false}
            pagination
            paginationServer
            onChangePage={this.handlePageChange}
            onChangeRowsPerPage={this.handlerecordsFilteredChange}
            onRowClicked={(row, event) => {
              this.props.history.push(`/dashboard/orders/${row.orderId}`);
            }}
            paginationPerPage={recordsFiltered}
            paginationTotalRows={recordsTotal}
            // paginationServerOptions={{
            //   totalPages:totalPages,
            //   totalRecords:recordsTotal,
            //   number:number
            // }}
            paginationRowsPerPageOptions={TableConfig.ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            responsive
            title={
              
                <p className="table-header" style={{marginLeft:"-10px"}}>
                  {"Found " + recordsTotal + " results"}
                </p>
             
            }
            customStyles={{
              rows: {
                style: {
                  cursor: 'pointer', 
                },
              },
            }}
          />

           {selectedRows?.length>0 &&
        <div className="btn-delivered" style={{display:"flex",position:"fixed",top:"90%",left:"50%"
        ,zIndex:"100"}}>
 {
  Object.values(selectedRowsOrderStatus).every((status) => status === "SHIPPED") && (
    <button onClick={this.handleDeliverStatus}>Mark As Delivered</button>
  )
}

{
  Object.values(selectedRowsOrderStatus).every((status) => status === "CREATED" || status === "PROCESSING") && (
    <button onClick={this.handleCancelOrder}>Cancel Order</button>
  )
}
        </div>}
        </Card>

       
        <PopUpForFilters
        selectFilterMain={this.state.selectedFiltersMain}
        onHide={(selectedFilters)=>this.handleClosePopUp(selectedFilters)}
        onClearFilter={(selectedFilters)=>this.handleHidePopUpForFilters(selectedFilters)}
        isVisible = {this.state.isPopUpForFiltersVisible}
        applyFilter={(selectedFilters)=> this.handleApplyFilters(selectedFilters)}/>
        
        <DeliveryStatusPopUp
        selectedRows={this.state.selectedRows}
        title="Delivery Status"
        onHide={this.handleHideDeliveryStatusPopUp}
        onSave={this.handleDeliveryStatusChange}
        isVisible={this.state.isPopUpVisibleForDeliveryStatus}
        />
        <BulkCancelOrderPopup
          selectedRows={this.state.selectedRows}
          title="Cancel Status"
          onHide={this.handleHideBulkCancelPopUp}
          onSave={this.handleBulkCancelChange}
          isVisible={this.state.isPopUpVisibleForBulkCancel}
          />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
  