import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Divider } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Card, Form } from "react-bootstrap";
import SelectCountry from "../../components/select/SelectCountry";
import SelectLanguage from "../../components/select/SelectLanguage";
import API from "../../utils/API";
import PhoneInput from "react-phone-input-2";
import "./react-phone-input.scss"
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from "@mui/icons-material/Visibility";
import { isMobile, isTablet, isAndroid, isIOS } from 'react-device-detect';

const BASE_URL_CUSTOMERS = "/api/v1/private/customer";


class AddCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: {},
      address: {},
      errors: {},
      pageType:"",
    };
  }

  numberCheck = async (value, data, event, formattedValue) => {
    const { customer } = this.state
    if (value.slice(data.dialCode.length).length > 6) {
      const contact = await (data.dialCode + value.slice(data.dialCode.length));
      this.setState({ customer: { ...customer, mobNumber: contact } });
    } else {
      // setPhone("");
    }
  };
  phonerCheck = async (value, data, event, formattedValue) => {
    const { address } = this.state
    if (value.slice(data.dialCode.length).length > 6) {
      const contact = await (data.dialCode + value.slice(data.dialCode.length));
      this.setState({ address: { ...address, mobNumber: contact } });
    } else {
      // setPhone("");
    }
  };

  componentDidMount(){
    window.addEventListener('resize', this.handleResize());
  }
  handleResize = ()=>{
    this.setState({pageType:this.getPageType()})
  }
  
  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.handleResize);
  // }
   getPageType = () => {
    return window.innerWidth > 770 ? 'web' : 'mWeb';
  };

  handleChange = ({ currentTarget: input }) => {
    const { customer, address } = this.state;
    customer[input.name] = input.value;
    this.setState({ customer });

    const errors = this.validate(customer, address);
    this.setState({ errors: errors || {} });
  }
  
  handleCheckboxChange = (name, isChecked) => {
    const customer = { ...this.state.customer };
    customer[name] = isChecked;
    this.setState({ customer });

  };

  handleAddressChange = ({ currentTarget: input }) => {
    const { customer, address } = this.state;
    address[input.name.substring(input.name.indexOf(".") + 1)] = input.value;
    this.setState({ address });

    const errors = this.validate(customer, address);
    this.setState({ errors: errors || {} });

  };
  handleDropdownLanguage = (option) => {
    const { customer, address } = this.state;

    this.setState({
      customer: { ...customer, language: option.value },
    }, () => {
      const errors = this.validate({ ...customer, language: option.value }, address);
      this.setState({ errors: errors || {} });
    });
  }
  handleDropdownAddress = (option) => {
    console.log("option",option)
    const { customer, address } = this.state;
    this.setState({
      address: {
        ...address,
        country: option?.value,
        countryCode: option?.value,

      },
    });

    this.setState({
      customer: {
        ...customer,
        country:option?.id,
        countryCode: option?.value,

      },
    });
    const errors = this.validate(
      customer,

      {
        ...address,
        country: option?.value,
        countryCode: option?.value,
      }

    );
    this.setState({ errors: errors || {} });
  }
  getDeviceType=()=> {
    if (isMobile) {
      if (isAndroid) {
        return 'Android';
      } else if (isIOS) {
        return 'iOS';
      } else {
        return 'Mobile';
      }
    } else if (isTablet) {
      return 'Tablet';
    } else {
      return 'Desktop';
    }
  }
  saveCustomer = async () => {
    const { customer, address } = this.state;

    const errors = this.validate(customer, address);
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields or empty fields!");
      return;
    }


    try {
      this.showLoading();

      const payload = Object.assign({}, customer);
      const addressPayload = address
        ? { ...address, isDefaultAddress: true }
        : null;
      payload.newBillingAddress = [addressPayload];
      payload.newShippingAddress = [addressPayload];
      payload.deviceType=this.getDeviceType()
      payload.pageType=this.state.pageType
   
     
    const response = await API.post(BASE_URL_CUSTOMERS, payload);
    if (response.status === 201) {
      this.props.history.goBack();
      this.showSuccessNotification("Customer added successfully!");
    } 
  } catch (error) {
    console.log("saveCustomer:: Error on adding customer!", error);
    this.showErrorNotification(
      "Error on adding customer!" + (error.response.data.message ?? "Unknown error")
    );
  } finally {
    this.hideLoading();
  }
}

  validate = (customer, address) => {
    const errors = {};
    if (customer && !customer.firstName) {
      errors.customerfirstName = 'First name is required.';
    }
    if (!customer.lastName) {
      errors.customerlastName = 'Last name is required.';
    }
    if (!customer.language) {
      errors.language = 'Language is required.';
    }
    if (!customer.emailAddress) {
      errors.emailAddress = 'Email is required.';
    }
    else if (!isValidEmail(customer.emailAddress)) {
      errors.emailAddress = 'Invalid email address.';
    }
    if (!customer.password) {
      errors.password = 'Password is required.';
    }
    if(!customer.country){
      errors.country ="Country is required"
    }
    else if (!isValidEmail(customer.emailAddress)) {
      errors.emailAddress = 'Invalid email address.';
    }
    // if (address && !address.country) {
    //   errors.country = "Country is required.";
    // }
    if (address && !address.firstName) {
      errors.firstName = "first name is required.";
    }
    if (address && !address.lastName) {
      errors.lastName = "Last name is required.";
    }
    if (address && !address.address) {
      errors.address = "Address is required.";
    }
    if (address && !address.apartment) {
      errors.apartment = "Apartment is required.";
    }
    if(address && !address.city){
      errors.city ="City is required"
    }
    if(address && !address.postalCode){
      errors.postalCode ="Postal code is required"
    }
    if (address && !address.stateProvince) {
      errors.stateProvince = "Emirate is required.";
    }


    function isValidEmail(email) {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
    return Object.keys(errors).length === 0 ? null : errors;
  }


  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });


  render() {
    const { customer = {}, address = {} } = this.state;
    const formCustomerOverview = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="firstName">First Name*</label>
            <input
              required
              type="text"
              value={customer.firstName ?? ""}

              onChange={this.handleChange}
              id="firstName"
              name="firstName"
              placeholder="first Name"
              className="form-control"

            />
            {this.state.errors.customerfirstName && <div className="help-block">{this.state.errors.customerfirstName}</div>}
          </div>
          <div className="form-group col">
            <label htmlFor="lastName">Last Name*</label>
            <input
              required
              type="text"
              value={customer.lastName ?? ""}
              onChange={this.handleChange}
              id="lastName"
              name="lastName"
              placeholder="Last Name"
              className="form-control"

            />
            {this.state.errors.customerlastName && <div className="help-block">{this.state.errors.customerlastName}</div>}
          </div>
        </div>
        <div className="row mt-3">
          <div className="form-group col">
            <label htmlFor="language">Language*</label>
            <SelectLanguage
              required
              id="language"
              name="language"
              value={customer.language}

              onChange={this.handleDropdownLanguage}
            />
            {this.state.errors.language && <div className="help-block">{this.state.errors.language}</div>}
            <span id="languageHelp" class="form-text text-muted">
              This customer will receive notifications in this language.
            </span>
          </div>
        </div>
        <div className="row mt-3">
          <div className="form-group col">
            <label htmlFor="emailAddress">Email*</label>
            <input type="email" name="fake_email" style={{ display: 'none' }} />
            <input
              required
              type="email"
              value={customer.emailAddress ?? ""}
              onChange={this.handleChange}
              id="emailAddress"
              name="emailAddress"
              placeholder="Email"
              className="form-control"
              autoComplete="off"
            />
            {this.state.errors.emailAddress && <div className="help-block">{this.state.errors.emailAddress}</div>}
          </div>
        </div>
              <div className="row mt-3"> 
                <div className="form-group col">
                  <label htmlFor="password">Password*</label>
                  <div style={{ position: 'relative' }}>
                    <input
                      required
                      type={this.state.showPassword ? 'text' : 'password'}
                      value={customer.password ?? ""}
                      onChange={this.handleChange}
                      id="password"
                      name="password"
                      placeholder="password"
                      className="form-control"
                      autoComplete="new-password"
                    />
                    {this.state.showPassword ? (
                      <Visibility
                        onClick={() => this.setState({ showPassword: false })}
                        style={{
                          position: 'absolute',
                          top: '50%',
                          right: '10px',
                          transform: 'translateY(-50%)',
                          cursor: 'pointer',
                        }}
                      />
                    ) : (
                      <VisibilityOff
                        onClick={() => this.setState({ showPassword: true })}
                        style={{
                          position: 'absolute',
                          top: '50%',
                          right: '10px',
                          transform: 'translateY(-50%)',
                          cursor: 'pointer',
                        }}
                      />
                    )}
                  </div>
                  {this.state.errors.password && <div className="help-block">{this.state.errors.password}</div>}
                </div>
              </div>
              <div className="row mt-3">
          <div className="form-group col">
            <label htmlFor="address.country">Country/region*</label>
            <SelectCountry
              required
              value={customer.countryCode}
              onChange={this.handleDropdownAddress}
              id="address.country"
              name="address.country"
            />
            {this.state.errors.country && <div className="help-block">{this.state.errors.country}</div>}
          </div>
        </div>
        <div className="row mt-3">
          <div className="form-group col">
            <label htmlFor="phone">Phone Number</label>
            <PhoneInput

              inputClass="font-poppins"
              country={customer?.countryCode?.toLowerCase() || "ae"}
              value={customer.mobNumber || ""}
              placeholder={"Enter Phone Number"}
              onChange={(value, data, event, formattedValue) => {
                this.numberCheck(value, data, event, formattedValue);
              }}
            />
          </div>
        </div>
        


        <div className="row mt-3">
          <div className="form-group col">
            <Form.Check
              type="checkbox"
              checked={customer.emailSubscription}
              onChange={(event) =>
                this.handleCheckboxChange(
                  "emailSubscription",
                  event.target.checked
                )
              }
              id="isAgreedToReceiveMarketingEmails"
              label="Customer agreed to receive marketing emails."
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="form-group col">
            <Form.Check
              type="checkbox"
              checked={customer.isAgreedToReceiveSMSMarketingMessages}
              onChange={(event) =>
                this.handleCheckboxChange(
                  "isAgreedToReceiveSMSMarketingMessages",
                  event.target.checked
                )
              }
              id="isAgreedToReceiveSMSMarketingMessages"
              label="Customer agreed to receive SMS marketing text messages."
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="form-group col">
            <span id="subscriptionHelp" class="form-text text-muted">
              You should ask your customers for permission before you subscribe
              them to your marketing emails or SMS.
            </span>
          </div>
        </div>
      </>
    );

    const formAddress = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="address.country">Country/region*</label>
            <SelectCountry
            // isCountryName={true}
            isDisabled={true}
              required
              value={customer?.countryCode}
              // onChange={this.handleDropdownAddress}
              id="address.country"
              name="address.country"
            />
            {/* {this.state.errors.country && <div className="help-block">{this.state.errors.country}</div>} */}
          </div>
        </div>
        <div className="row mt-3">
          <div className="form-group col">
            <label htmlFor="address.firstName">First Name*</label>
            <input
              required
              value={address.firstName ?? ""}
              onChange={this.handleAddressChange}
              type="text"
              id="address.firstName"
              name="address.firstName"
              placeholder="First name"
              className="form-control"
            />
            {this.state.errors.firstName && <div className="help-block">{this.state.errors.firstName}</div>}
          </div>
          <div className="form-group col">
            <label htmlFor="address.lastName">Last Name*</label>
            <input
              required
              value={address.lastName ?? ""}
              onChange={this.handleAddressChange}
              type="text"
              id="address.lastName"
              name="address.lastName"
              placeholder="Last name"
              className="form-control"
            />
            {this.state.errors.lastName && <div className="help-block">{this.state.errors.lastName}</div>}
          </div>
        </div>
        <div className="row mt-3">
          <div className="form-group col">
            <label htmlFor="address.address">Area name, Colony*</label>
            <input
              required
              value={address.address ?? ""}
              onChange={this.handleAddressChange}
              type="text"
              id="address.address"
              name="address.address"
              placeholder="Area name, Colony"
              className="form-control"
            />
            {this.state.errors.address && <div className="help-block">{this.state.errors.address}</div>}
          </div>
        </div>
        <div className="row mt-3">
          <div className="form-group col">
            <label htmlFor="address.apartment">Appartment name, Floor, Room no, City*</label>
            <input
              value={address.apartment ?? ""}
              onChange={this.handleAddressChange}
              type="text"
              id="address.apartment"
              name="address.apartment"
              placeholder="Appartment name, Floor, Room no, City"
              className="form-control"
            />
            {this.state.errors.apartment && <div className="help-block">{this.state.errors.apartment}</div>}
          </div>
        </div>
        <div className="row mt-3">
          <div className="form-group col">
            <label htmlFor="address.stateProvince">City*</label>

            <input
              required

              value={address.city ?? ""}

              onChange={this.handleAddressChange}
              type="text"
              id="address.city"
              name="address.city"
              placeholder="City"
              className="form-control"
            />
            {this.state.errors.city && <div className="help-block">{this.state.errors.city}</div>}
          </div>
        </div>
        <div className="row mt-3">
          <div className="form-group col">
            <label htmlFor="address.stateProvince">Postal Code*</label>

            <input
              required

              value={address.postalCode ?? ""}

              onChange={this.handleAddressChange}
              type="text"
              id="address.postalCode"
              name="address.postalCode"
              placeholder="Postal Code"
              className="form-control"
            />
            {this.state.errors.postalCode && <div className="help-block">{this.state.errors.postalCode}</div>}
          </div>
        </div>
        <div className="row mt-3">
          <div className="form-group col">
            <label htmlFor="address.stateProvince">State Province*</label>

            <input
              required

              value={address.stateProvince ?? ""}

              onChange={this.handleAddressChange}
              type="text"
              id="address.stateProvince"
              name="address.stateProvince"
              placeholder="State Province"
              className="form-control"
            />
            {this.state.errors.stateProvince && <div className="help-block">{this.state.errors.stateProvince}</div>}
          </div>
        </div>
        <div className="row mt-3">
          <div className="form-group col">
            <label htmlFor="phone">Phone Number</label>
            <PhoneInput
              inputClass="font-poppins"
              country={address?.countryCode?.toLowerCase() || "ae"}
              value={address.mobNumber || ""}
              placeholder={"Enter Phone Number"}
              onChange={(value, data, event, formattedValue) => {
                this.phonerCheck(value, data, event, formattedValue);
              }}
            />
          </div>
        </div>
      </>
    );

    const formTaxExemptions = (
      <>
        <div className="row">
          <div className="form-group col">
            <Form.Check
              type="checkbox"
              checked={customer.collectTax ?? false}
              onChange={(event) =>
                this.handleCheckboxChange("collectTax", event.target.checked)
              }
              id="collectTax"
              name="collectTax"
              label="Collect tax"
            />
          </div>
        </div>
      </>
    );

    const formNotes = (
      <>
        <div className="form-group col">
          <label htmlFor="notes">Note</label>
          <input
            type="text"
            value={customer.notes ?? ""}
            onChange={this.handleChange}
            id="notes"
            name="notes"
            className="form-control"
          />
        </div>
      </>
    );


    return (
      <div className="container">
        <div class="d-flex justify-content-start-0">
          <div style={{ alignSelf: "flex-start", marginRight: "1rem" }}>
            <Button
              onClick={() => this.props.history.goBack()}
             
              color="inherit"
              size="large"
            >
              <ArrowBackIcon />
            </Button>
          </div>
          <span
            className="mx-2 my-2"
            style={{ fontWeight: "600", fontSize: "1.25rem" }}
          >
            New customer
          </span>
          <div className="col text-end">
            <Button
              onClick={() => this.saveCustomer()}
              variant="contained"
              size="large"
              color="success"
              sx={{
                bgcolor: "#247A81",
                color: "white", 
                borderRadius: "20px",
                ":hover": {
                  bgcolor: "#125443",
                },
                textTransform: "capitalize",
              }}
            >
              Save
            </Button>
          </div>
        </div>

        <Divider className="mt-4 mb-3" sx={{ borderWidth: 2 }} />
        <div class="d-flex justify-content-between">
          <span
            className="mt-4"
            style={{ fontWeight: "500", fontSize: "1rem" }}
          >
            Customer overview
          </span>
          <div class="col-8">
            <Card style={{ background: "#FFFFFF" }}>
              <Card.Body>{formCustomerOverview}</Card.Body>
            </Card>
          </div>
        </div>

        <Divider className="mb-3" sx={{ borderWidth: 2 }} />
        <div class="d-flex justify-content-between">
          <div className="col">
            <p className="mt-4" style={{ fontWeight: "500", fontSize: "1rem" }}>
              Address
            </p>
            <p className="text-muted">The primary address of this customer</p>
          </div>
          <div class="col-8">
            <Card style={{ background: "#FFFFFF" }}>
              <Card.Body>{formAddress}</Card.Body>
            </Card>
          </div>
        </div>

        <Divider className="mb-3" sx={{ borderWidth: 2 }} />
        <div class="d-flex justify-content-between">
          <span
            className="mt-4"
            style={{ fontWeight: "500", fontSize: "1rem" }}
          >
            Tax exemptions
          </span>
          <div class="col-8">
            <Card style={{ background: "#FFFFFF" }}>
              <Card.Body>{formTaxExemptions}</Card.Body>
            </Card>
          </div>
        </div>

        <Divider className="mb-3" sx={{ borderWidth: 2 }} />
        <div className="d-flex justify-content-between">
          <div className="col">
            <p className="mt-4" style={{ fontWeight: "500", fontSize: "1rem" }}>
              Notes
            </p>
            <p className="text-muted">Add notes about your customer.</p>
          </div>
          <div class="col-8">
            <Card style={{ background: "#FFFFFF" }}>
              <Card.Body>{formNotes}</Card.Body>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);
