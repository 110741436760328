import React, { useEffect, useState, useCallback } from "react";
import { Card } from 'react-bootstrap';
import DeleteIcon from "../../../../assets/img/delete.png";
import CrossIcon from "../../../../assets/img/cross_icon.png";
import { Modal } from "react-bootstrap";
import './pricingOnCountryLevel.scss';
import SubscriptionForm from "../../../../components/SubscriptionForm";
import { connect } from "react-redux";

const updateArray = (array, index,fieldName, value) => {
    const newArray = array.map((item, i) => {
      if (i === index ) {
        return { ...item,[fieldName]: value };
      }
      return item;
    });
    return newArray;
  };

  const addVariant = (array, index, fieldName, value) => {
    console.log('addVariant', array, index, fieldName, value);
    const newArray = array.map((item, i) => {
      if (i === index) {
        // const existingVariantNames = item.variantData.map(data => data.vaiantName);
        // const newVariants = value.filter(variant => !existingVariantNames.includes(variant.vaiantName));
        // return { ...item,[fieldName]: [...item.variantData,...newVariants] };
        return { ...item,[fieldName]: [...item.variantData,...value] };
      }
      return item;
    });
    
    return  newArray; // Return null if duplicates were found
  };
  const updateVariant = (array, index, variantIndex, fieldName, value) => {
    const newArray = array.map((item, i) => {
      if (i === index ) {
        const modifiedVariantData = item.variantData.map((variant,index )=> {
           
            if(index == variantIndex){
                    
                return { ...variant, [fieldName]: value};
            }
            else{
                return { ...variant};
            }
          });
          return { ...item, variantData: modifiedVariantData };
      }else{
        return item;
      }
    });
    return newArray;
  };


  const deleteVariant = (array, index, variantIndex) => {
    const newArray = array.map((item, i) => {
      if (i === index ) {
        const filterItem = item.variantData.filter((_, i) => i !== variantIndex);
        return { ...item, variantData: filterItem };
      }else{
        return item;
      }
    });
    return newArray;
  };
  const newPricingData= {
     status:true,
     countryId:"",
     listPrice:"",
     discount:"",
     finalPrice:"",
     pageTitle:"",
     description:"",
     urlHandle:"",
     keyword:"",
     variantData:[],
     pageTitleArabic:"",
     descriptionArabic:"",
     keywordArabic:"",
    }
const  PricingOnCountryLevel=(props) => {
 const {pricingDetails=[],countryData=[], variantData=[], setPricingDetails={}, pricingError={} } = props
    const [ pricingData , setPricingData ] = useState([]);
    const [showListOfVariant, setShowListOfVariant] = useState(false);
    const [ clickedVariantImageIndex , setClickedVariantImageIndex] = useState(null);
    const [ selectedVariant, setSelectedVariant] = useState([]);
    const [ existingSelectVarient,setExistingSelectVarient] = useState([]);
    // const [ selectedVarientIndex,setSelectedVarientIndex] = useState(null)
    console.log("productPricingError",pricingError)
    useEffect(() => {
        const variantNamesByIndex = pricingDetails.map((item) =>
          item.variantData ? item.variantData.map((data) => data.vaiantName) : []
        );
        setExistingSelectVarient(variantNamesByIndex);
      }, [pricingDetails]);
console.log("existingSelectVarient",existingSelectVarient)
    const onAddNewCountry = () =>{
        setPricingDetails([newPricingData,...pricingDetails])
        console.log("pricingDetails",pricingDetails)
    }

    const onAddVariant = (data) =>{
           if(selectedVariant && selectedVariant.length > 0){
            const updatedArray = addVariant(pricingDetails,clickedVariantImageIndex,"variantData",selectedVariant);
            setPricingDetails(updatedArray);
            setSelectedVariant([])
            setShowListOfVariant(false)
           }
         
    }
    const onSelectVariant = (data) =>{
       
        const isAlreadyExist = selectedVariant.find((select) => select.vaiantName == data.vaiantName);
       
        if(isAlreadyExist){
            const filterItem = selectedVariant.filter((select, i) => select.vaiantName != data.vaiantName);
            setSelectedVariant(filterItem)
        }else{
            data['listPrice'] = "";
            data['discount'] = "";
            data['finalPrice'] ="";
            setSelectedVariant([...selectedVariant,data])
        }
        
    }
   
    const onChangePricingValue = ({fieldName,value,index}) => {
       const updatedArray = updateArray(pricingDetails,index,fieldName,value);
       setPricingDetails(updatedArray);
    }
   
    const onDeleteVariant = (index) => {
       const filterItem = pricingDetails.filter((_, i) => i !== index);
       setPricingDetails(filterItem)
    }


    const onChangeVaraintPrice = ({fieldName,value,index,variantIndex}) => {
        const updatedArray = updateVariant(pricingDetails,index,variantIndex,fieldName,value);
        setPricingDetails(updatedArray);
     }

    const onRemoveVaraint = ({index,variantIndex}) => {
        const removedItem = deleteVariant(pricingDetails,index,variantIndex);
        setPricingDetails(removedItem)
        
    }

    const showSuccessNotification = (notificationMessage) =>
        showNotification(notificationMessage, true);
      
      const showErrorNotification = (notificationMessage) =>
        showNotification(notificationMessage, false);
      
      const showNotification = (notificationMessage, isSuccessMessage) =>
        props.showNotificationMessage({
          notificationMessage,
          successMessage: isSuccessMessage,
          showNotification: true,
        });
    
    console.log("pricingDetailspricingDetails",pricingDetails)
    return (
    <div className="pricing-on-country-level-wrapper">
          <Card body>
            <div>
                <div className="pricing-header-container">
                    <label className="label-txt">Pricing on Country Level</label>
                    <div className="action-btn" onClick={()=>onAddNewCountry()}>+ Add Country</div>
                </div>
                {
                    pricingDetails?.map((data,index)=>{
                        return(
                            <>
                            <div className="pricing-list-container" key={index}>
                            <div className="price-list-row-container">
                                <div className="columnalignactive">
                                    <select value={data.countryId || ""} onChange={(e)=>onChangePricingValue({
                                        fieldName:"countryId",
                                        value:e.target.value,
                                        index:index
                                    })} >  
                                       <option default>Select Country</option>
                                        {countryData.map((ctry) => <option value={ctry.id}>{ctry.name}</option>)}
                                     </select>
                                </div>
                                <div className="columnalignactive">
                                    <div className="group">
                                        <label for="name" className="label-title-txt">List Price*</label>
                                        <input type="number" onWheel={(e)=>e.target.blur()} style={{ width: "7rem" }} value={data.listPrice} onChange={(e)=>onChangePricingValue({
                                        fieldName:"listPrice",
                                        value:e.target.value,
                                        index:index
                                    })} />
                                    </div>
                                </div>
                                <div  className="columnalignactive">
                                    <div className="group">
                                        <label for="name" className="label-title-txt">Discount*</label>
                                        <input type="number" disabled onWheel={(e)=>e.target.blur()} style={{ width: "7rem",backgroundColor:"#d4d4d5" }} value={data.discount } onChange={(e)=>onChangePricingValue({
                                        fieldName:"discount",
                                        value:e.target.value,
                                        index:index
                                    })}   />
                                    </div>
                                </div>
                                <div  className="columnalignactive">
                                    <div className="group">
                                        <label for="name" className="label-title-txt">Final Price*</label>
                                        <input type="number" onWheel={(e)=>e.target.blur()} style={{ width: "7rem" }} value={data.finalPrice } onChange={(e)=>onChangePricingValue({
                                        sectionName:"pricing",
                                        fieldName:"finalPrice",
                                        value:e.target.value,
                                        index:index
                                    })}   />
                                    </div>
                                </div>
                               
                                <div className="group variant-status">
                                    <div className="label-title-txt">Active</div>
                                    <input type="checkbox" checked={data.status}  onChange={(e)=>onChangePricingValue({
                                        sectionName:"pricing",
                                        fieldName:"status",
                                        value:!data.status,
                                        index:index, 
                                    })}  class="checkbox-round"  />
                                </div> 
                                <div className="group action" onClick={()=>onDeleteVariant(index)}>
                                    <img src={DeleteIcon} alt="delete-icon"/>
                                </div> 
                            </div>
                            <div className="seo-container">
                                <div className="header-txt">SEO at country level</div>
                                <div className="alignrow seo-input-container">
                                    <div className="columnalign">
                                        <div className="alignrow">
                                        <div className="columnalignactive">
                                            <div className="group">
                                                <label for="name" className="label-title-txt">Page Title</label> 
                                                <input type="text"  value={data.pageTitle || ""} size="100" 
                                                 onChange={(e)=>onChangePricingValue({
                                        fieldName:"pageTitle",
                                        value:e.target.value,
                                        index:index
                                    })}  style={{width:'100%'}} />
                                            </div>
                                        </div>
                                        <div className="columnalignactive">
                                            <div className="group">
                                                <label for="name" className="label-title-txt">Page Title (Arabic)</label> 
                                                <input type="text"  value={data.pageTitleArabic || ""} size="100" className="ar-inputfieldsize"
                                                 onChange={(e)=>onChangePricingValue({
                                        fieldName:"pageTitleArabic",
                                        value:e.target.value,
                                        index:index
                                    })}  style={{width:'100%'}} />
                                            </div>
                                        </div>
                                        </div>

                                        <div className="alignrow">
                                        <div className="columnalignactive">
                                        <div className="group">
                                            <label for="name" className="label-title-txt">Meta Description</label> 
                                            <textarea rows={30}  style={{height:"10rem",width:"100%"}}
                                        cols={100}  value={data.description || ""} onChange={(e)=>onChangePricingValue({
                                            fieldName:"description",
                                            value:e.target.value,
                                            index:index
                                        })} />
                                        </div>
                                        </div>
                                        <div className="columnalignactive">
                                        <div className="group">
                                            <label for="name" className="label-title-txt">Meta Description (Arabic)</label> 
                                            <textarea rows={30}  style={{height:"10rem",width:"100%"}} className="ar-inputfieldsize"
                                        cols={100}  value={data.descriptionArabic || ""} onChange={(e)=>onChangePricingValue({
                                            fieldName:"descriptionArabic",
                                            value:e.target.value,
                                            index:index
                                        })} />
                                        </div>
                                        </div>
                                        </div>

                                       
                                        <div className="alignrow">
                                        <div className="columnalignactive">
                                            <div className="group">
                                                <label for="name" className="label-title-txt">Keywords</label>  
                                                <input type="text"  value={data.keyword || ""}  size="100" onChange={(e)=>onChangePricingValue({
                                        fieldName:"keyword",
                                        value:e.target.value,
                                        index:index  
                                    })} 
                                    style={{width:'100%'}} />
                                            </div>
                                        </div>
                                        <div className="columnalignactive">
                                            <div className="group">
                                                <label for="name" className="label-title-txt">Keywords (Arabic)</label>  
                                                <input type="text"  value={data.keywordArabic || ""} className="ar-inputfieldsize" size="100" onChange={(e)=>onChangePricingValue({
                                        fieldName:"keywordArabic",
                                        value:e.target.value,
                                        index:index  
                                    })}
                                    style={{width:'100%'}}  />
                                            </div>
                                        </div>
                                        </div>
                                       
                                        {/* <div className="columnalignactive">
                                            <div className="group">
                                                <label for="name" className="label-title-txt">URL Handle*</label>  
                                                <input type="text"  value={data.urlHandle || ""} onChange={(e)=>onChangePricingValue({
                                        fieldName:"urlHandle",
                                        value:e.target.value,
                                        index:index  
                                    })}  />
                                            </div>
                                        </div> */}
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="variant-price-container">
                                <div className="add-variant-btn" onClick={()=>
                                    {
                                        setClickedVariantImageIndex(index)
                                        setShowListOfVariant(true)
                                    }
                                    }>+ Variants</div>
                                <div className="list-of-variant">
                                    {
                                        data && data.variantData && data.variantData.map((variantData,variantIndex)=>{
                                            return(
                                                <div className="variant-price-row-container">
                                                <div className="columnalignactive">
                                                    <div>{variantData.vaiantName|| ""}</div>
                                                </div>
                                                <div className="columnalignactive">
                                                    <div className="group">
                                                        <label for="name">List Price*</label>
                                                        <input type="number" onWheel={(e)=>e.target.blur()} onChange={(e)=>onChangeVaraintPrice({
                                        sectionName:"pricing",
                                        fieldName:"listPrice",
                                        value:e.target.value,
                                        index:index,
                                        variantIndex:variantIndex
                                    })} style={{ width: "7em" }} value={variantData.listPrice }   />
                                                    </div>
                                                </div>
                                                <div  className="columnalignactive">
                                                    <div className="group">
                                                        <label for="name">Discount*</label>
                                                        <input type="number" onWheel={(e)=>e.target.blur()} value={variantData.discount } onChange={(e)=>onChangeVaraintPrice({
                                        sectionName:"pricing",
                                        fieldName:"discount",
                                        value:e.target.value,
                                        index:index,
                                        variantIndex:variantIndex
                                    })}  style={{ width: "7em" }}   />
                                                    </div>
                                                </div>
                                                <div  className="columnalignactive">
                                                    <div className="group">
                                                        <label for="name">Final Price*</label>
                                                        <input type="number" onWheel={(e)=>e.target.blur()} style={{ width: "7em" }} value={variantData.finalPrice}   onChange={(e)=>onChangeVaraintPrice({
                                        sectionName:"pricing",
                                        fieldName:"finalPrice",
                                        value:e.target.value,
                                        index:index,
                                        variantIndex:variantIndex
                                    })} />
                                                    </div>
                                                </div>
                                                <div className="group action" onClick={()=>onRemoveVaraint({  index:index,
                                        variantIndex:variantIndex})}>
                                                    <div>Remove</div>
                                                    <img src={CrossIcon} alt="delete-icon"/>
                                                </div> 
                                            </div>
                                            )
                                        })
                                    }
                                   
                                </div>
                            </div>
                            {pricingError && pricingError[index] && <span style={{fontSize:'12px',color:'red'}} >{pricingError[index]}</span>}
                            
                            
                            
                            </div>
                            
                           
                            </>
                        )
                    })
                }

                <div>
                    <Modal show={showListOfVariant} onHide={()=>setShowListOfVariant(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title className="aligncenter">
                                <div className="aligncenter">Select Variant</div> 
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                variantData
                                .filter(variant => clickedVariantImageIndex !== null && !existingSelectVarient[clickedVariantImageIndex]?.includes(variant.vaiantName))
                                .map((data,index)=>{
                                    console.log("datadatadata",data)
                                    if(data.vaiantName && data.qty){
                                        return(
                                            <div className="columnalign" style={{marginTop:"12px"}}>
                                            <div className="alignrowpopup" style={{alignItems:'center'}}>  
                                                <div className="variantimage"> 
                                                    <img className="variantimage" src={data.variantImg} />
                                                </div>
                                            <div className="namesize">{data.vaiantName}</div>
                                            <div>    <input name={"variant.name"} type="checkbox" onChange={()=>onSelectVariant(data)} /> </div>
                                            </div>
                                        </div>
                                        )
                                    }
                                })
                            }
                            <div style={{backgroundColor:"#247A81",paddingTop:'5px',paddingBottom:"5px",width:"99px",color:"white",borderRadius:"19px",cursor:"pointer",textAlign:"center",float:"right",marginTop:"15px"}} onClick={()=>onAddVariant()}>Add</div>
                       
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
          </Card>
    </div>
  )
}
function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(PricingOnCountryLevel);